import ChatArea from './Components/ChatArea';
import RightBar from './Components/RightBar';
import LeftBar from './Components/LeftBar';
import React, { useEffect, useRef, useState } from 'react';
import { App as CapacitorApp } from '@capacitor/app';
import { getCurrentUser } from 'aws-amplify/auth';
import logoImage from './logo512.png';
import {
    isDesktopVersion,
    isMobileVersion,
    isClient,
    isElectron,
    isAndroidApk,
    getConfig,
    saveConfigToLocalStorage,
    getConversationIdListDB,
    setConversationIdList2DB,
    nextConversationId,
    getConversionById,
    setConversation
} from './Utils';
import { getUserInfo, getLatestVersionInfo } from './Lib/server-api';
import { useTranslation } from 'react-i18next';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Bars4Icon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { GlobalContext } from './Contexts/GlobalContext';
import Modal from 'react-modal';
import { chatAIModels } from "./Lib/price-models";

// Ensure screen readers only see the modal when it's open
Modal.setAppElement('#root');


function App() {
    const isFirstRun = useRef(true);
    const [conversationIdList, setConversationIdListHook] = useState(getConversationIdListDB()); // conversation id list, it will be used to generate the left bar
    const [conversationId, setConversationId] = useState(-1); // current conversation id, it will be -1 when no conversation selected

    const [globalLoading, setGlobalLoading] = useState(true);
    const [config, setConfig] = useState(getConfig());
    const [userData, setUserData] = useState(null); // user data from server, its only for cache use.
    const [authUser, setAuthUser] = useState({}); // can not default to null, otherwise set authUser will not trigger useEffect
    const authUserRef = useRef(authUser); // the ref of authUser, we use this to prevent useEffect infinite loop
    const [flip, setFlip] = useState(true);
    const [menu, setMenu] = useState(false);
    const [hideWelcome, setHideWelcome] = useState(localStorage.hideWelcome || false);
    const [isDarkMode, setDarkMode] = useState(null);
    const [hideLeftBar, setHideLeftBar] = useState(localStorage.hideLeftBar || false);
    const { t } = useTranslation();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [readyToRestart, setReadyToRestart] = useState(false);
    const [updateProgress, setUpdateProgress] = useState({
        total: 0,
        delta: 0,
        transferred: 0,
        percent: 0,
        bytesPerSecond: 0,
    });
    const [exitModalIsOpen, setExitModalIsOpen] = useState(false);

    /**
     * @type {{id: number, text: string}}
     * we use this to pass user input cache, so that when user switch between different conversation, the input text will not be lost.
     * id for conversation id, text for input text, we use id to prevent different conversation use the same input text.
     */
    const [inputCache, setInputCache] = useState({ id: 0, text: '' });
    /**
     * @type {{id: number, images: [string]}}
     * we use this to pass user input cache, so that when user switch between different conversation, the input text will not be lost.
     * id for conversation id, text for input text, we use id to prevent different conversation use the same input text.
     */
    const [imageCache, setImageCache] = useState({ id: 0, images: [] });

    // make it better layout size on mobile
    useEffect(() => {

        const updateAppContainerHeight = () => {
            document.querySelector('.App').style.height = `${window.innerHeight}px`;
        };

        updateAppContainerHeight();
        window.addEventListener('resize', updateAppContainerHeight);
        window.chatAIVersion = process.env.REACT_APP_VERSION // this one for matomo to read client version
        const containerId = isClient() ? '522PgF2Y' : 'meTdWD6l';
        // <!-- Matomo Tag Manager -->
        const _mtm = window._mtm = window._mtm || [];
        _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
        (function () {
            const d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
            g.async = true;
            g.src = `https://a.logcg.com/js/container_${containerId}.js`;
            s.parentNode.insertBefore(g, s);
        })();
        // <!-- End Matomo Tag Manager -->
        return () => {
            window.removeEventListener('resize', updateAppContainerHeight);
        };
    }, []);

    // Android back button listener
    useEffect(() => {
        // 监听返回键事件
        const backButtonListener = CapacitorApp.addListener('backButton', (e) => {
            // 获取当前页面状态或菜单状态
            if (exitModalIsOpen) {
                // If exit modal is open, dismiss it on back press
                setExitModalIsOpen(false);
            } else if (menu) {
                // 如果菜单打开，则关闭菜单
                setMenu(false);
            } else if (!hideLeftBar) {
                // 如果菜单未打开且左侧菜单未隐藏，隐藏左侧菜单
                setHideLeftBar(true);
            } else {
                // Otherwise, show the exit modal
                setExitModalIsOpen(true);
            }
        });
        // 组件卸载时移除监听器
        return () => {
            backButtonListener.then(handler => handler.remove());
        };
    }, [exitModalIsOpen, hideLeftBar, menu]);

    // check new version on client side
    useEffect(() => {
        if (!isFirstRun.current) {
            return;
        }

        if (isClient()) {
            if (isElectron()) {
                window.electronAPI.onUpdateDownloaded((event, arg) => {
                    setReadyToRestart(true);
                });
                window.electronAPI.onUpdateProgress((event, arg) => {
                    /**
                     * {
                     *     total: number;
                     *     delta: number;
                     *     transferred: number;
                     *     percent: number;
                     *     bytesPerSecond: number;
                     * }
                     */
                    setUpdateProgress(arg)
                    setModalIsOpen((prev) => {
                        if (!prev) {
                            return true;
                        }
                        return prev;
                    });
                })
                // Listen for the message from the main process
                window.electronAPI.onNewVersionAvailable((event, arg) => {
                    const msg = `${t('发现新版本，当前')}: ${process.env.REACT_APP_VERSION}， ${t('更新')} ${arg}?`;
                    if (!window.confirm(msg)) {
                        return;
                    }
                    window.electronAPI.downloadUpdate()
                });
            } else {
                getLatestVersionInfo().then((data) => {
                    if (isAndroidApk() && data.android > process.env.REACT_APP_VERSION) {
                        const msg = `${t('发现新版本，当前')}: ${process.env.REACT_APP_VERSION}， ${t('更新')} ${data.android}?`;
                        if (!window.confirm(msg)) {
                            return;
                        }
                        const url = 'https://im.logcg.com/chat-ai';
                        window.open(url, '_blank');
                    }
                });
            }
        }
    }, [t]);

    // hide left bar on mobile
    useEffect(() => {
        if (isMobileVersion()) {
            setHideLeftBar(true);
        }
    }, []);

    // show welcome on first run
    useEffect(() => {
        getCurrentUser()
            .then((user) => setAuthUser(user))
            .catch(() => {
                localStorage.removeItem('hideWelcome');
                setAuthUser(null);
            }).finally(() => {
            isFirstRun.current = false;
        });

        if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            setDarkMode(true);
        } else {
            setDarkMode(false);
        }
    }, []);

    // update when authUser changed
    useEffect(() => {
        if (isFirstRun.current) {
            return;
        }
        authUserRef.current = authUser;
        if (authUser) {
            getUserInfo(authUser).then((data) => {
                setUserData(data);
                if (data.config) {
                    // here we check if the model name is valid, if not, we use gpt-4o as default
                    data.config.modelName = chatAIModels.hasOwnProperty(data.config.modelName) ? data.config.modelName : 'gpt-4o';
                    console.log('Sync from cloud config:', data.config)
                    setConfig((prevConfig) => ({ ...prevConfig, ...data.config }));
                }
                setGlobalLoading(false);
            });
        } else {
            setUserData(null);
            setGlobalLoading(false);
        }
    }, [authUser]);

    // update local when config changed
    useEffect(() => {
        if (isFirstRun.current) {
            return;
        }
        saveConfigToLocalStorage(config, authUserRef.current);
    }, [config]);

    useEffect(() => {
        setConversationIdList2DB(conversationIdList);
    }, [conversationIdList]);

    useEffect(() => {
        if (isFirstRun.current) {
            return;
        }
        if (isDarkMode === null) {
            return;
        }
        if (isDarkMode) {
            document.documentElement.classList.add('dark');
            // To set the StatusBar text color and icons, for example to light
            StatusBar.setStyle({ style: Style.Dark }).catch((e) => {
                //  Error: status bar plugin is not implemented on web
            });

        } else {
            document.documentElement.classList.remove('dark');
            // To set the StatusBar text color and icons, for example to dark
            StatusBar.setStyle({ style: Style.Default }).catch((e) => {
                //  Error: status bar plugin is not implemented on web
            });
        }

        localStorage.theme = isDarkMode ? 'dark' : 'light';
    }, [isDarkMode]);

    function handleHideWelcome() {
        setHideWelcome(true);
        localStorage.hideWelcome = true;
    }

    function handleMenuClick() {
        setMenu(!menu);
        if (!menu) {
            setGlobalLoading(true);
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            setGlobalLoading(false);
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }

    function handleClickOutside(event) {
        // prevent default will cause the menu page to be un-clickable
        const rightBar = document.getElementById('menu');
        const menuBtn = document.getElementById('menuBtn');
        if ((rightBar && rightBar.contains(event.target)) || (menuBtn && menuBtn.contains(event.target))) return;
        document.removeEventListener('mousedown', handleClickOutside);
        setMenu(false);
    }

    function handleHideLeftBar(event) {
        event.preventDefault();
        if (!hideLeftBar) {
            localStorage.hideLeftBar = true;
        } else {
            localStorage.removeItem('hideLeftBar');
        }
        setHideLeftBar(!hideLeftBar);
    }

    async function startNewConversation() {
        //get max number of conversationIdList
        const nextId = nextConversationId(conversationIdList);
        setConversationIdListHook([...conversationIdList, nextId]);
        const newConversation = {
            title: t('新会话'),
            messages: [],
            id: nextId,
            inputCache: '',
            model: '',
            imageCache: []
        };

        const currentConversation = await getConversionById(conversationId);
        if (currentConversation) {
            if (inputCache.id === conversationId) {
                currentConversation.inputCache = inputCache.text;
            }
            if (imageCache.id === conversationId) {
                currentConversation.imageCache = imageCache.images;
            }
            await setConversation(currentConversation);
        }
        if (conversationId === -1 && conversationIdList.length === 0) {
            // special case for empty conversations
            newConversation.inputCache = inputCache.text;
            newConversation.imageCache = imageCache.images;
        }
        await setConversation(newConversation);
        setTimeout(() => {
            setConversationId(nextId);
        }, 100);
    }

    return (
        <div className="App h-screen w-full bg-gray-100 dark:bg-slate-900 flex">
            <GlobalContext.Provider
                value={{
                    conversationId,
                    setConversationId,
                    authUser,
                    setAuthUser,
                    flip,
                    setFlip,
                    menu,
                    setMenu,
                    hideWelcome,
                    setHideWelcome,
                    isDarkMode,
                    setDarkMode,
                    hideLeftBar,
                    setHideLeftBar,
                    inputCache,
                    setInputCache,
                    imageCache,
                    setImageCache,
                    config,
                    setConfig,
                    globalLoading,
                    setGlobalLoading,
                    userData,
                    setUserData,
                    conversationIdList,
                    setConversationIdList: setConversationIdListHook,
                    startNewConversation,
                }}
            >
                <Modal
                    isOpen={modalIsOpen}
                    contentLabel="Logout Confirmation"
                    className="bg-white dark:bg-gray-800 rounded-md p-6 w-full max-w-lg m-4 focus:outline-none"
                    overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-50 dark:bg-opacity-80 flex items-center justify-center z-40"
                >
                    <h2 className="text-xl font-bold mb-6 text-gray-800 dark:text-gray-100">{readyToRestart ? t('点击"更新"按钮进行更新') : t('加载中')}...</h2>
                    {!readyToRestart &&
                        <p className="text-gray-700 dark:text-gray-300">{`${(updateProgress.transferred / 1000 / 1000).toFixed(2)}MB/${(updateProgress.total / 1000 / 1000).toFixed(2)}MB (${updateProgress.percent.toFixed(2)}%)  ${(updateProgress.bytesPerSecond / 1000 / 1000).toFixed(2)}MB/s`}</p>
                    }
                    <div className="mt-6">
                        <button
                            disabled={!readyToRestart}
                            className="disabled:opacity-50 bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded-md mr-3 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 focus:outline-none"
                            onClick={() => {
                                window.electronAPI.restart();
                            }}
                        >
                            {t('更新')}
                        </button>
                    </div>
                </Modal>
                <Modal
                    isOpen={exitModalIsOpen}
                    onRequestClose={() => setExitModalIsOpen(false)}
                    contentLabel="Exit Confirmation"
                    className="bg-white dark:bg-gray-800 rounded-md p-6 w-full max-w-lg m-4 focus:outline-none"
                    overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-50 dark:bg-opacity-80 flex items-center justify-center z-50"
                >
                    <h2 className="text-xl font-bold mb-6 text-gray-800 dark:text-gray-100">{t('退出应用')}</h2>
                    <p className="text-gray-700 dark:text-gray-300">{t('确定要退出吗？')}</p>
                    <div className="mt-6">
                        <button
                            className="bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded-md mr-3 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 focus:outline-none"
                            onClick={() => { CapacitorApp.exitApp(); }}
                        >
                            {t('退出')}
                        </button>
                        <button
                            className="bg-gray-300 dark:bg-gray-600 text-black dark:text-white px-4 py-2 rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 focus:outline-none"
                            onClick={() => setExitModalIsOpen(false)}
                        >
                            {t('取消')}
                        </button>
                    </div>
                </Modal>
                {!hideWelcome && <div
                    className={'absolute h-full w-full bg-white dark:bg-black bg-opacity-50 dark:bg-opacity-50 z-40 flex justify-center'}>
                    <div
                        role={'dialog'}
                        aria-label={'欢迎'}
                        className={'bg-blue-100 dark:bg-slate-900 rounded-xl md:px-0 px-10 md:mx-0 mx-4 md:w-1/2 h-1/2 shadow-xl absolute z-50 top-1/4 md:left-1/4 flex flex-col justify-center'}>
                        <img src={logoImage} className={'h-1/3 mx-auto md:mt-5 mt-0 z-50'} alt="Logo"
                             aria-label={'logo'}/>
                        {authUser ?
                            <div>
                                <div
                                    className={'md:text-2xl text-lg font-bold text-center md:mt-8 mt-4 dark:text-white'}>{t('欢迎来到落格智聊')}
                                </div>
                                <div
                                    className={'md:text-lg text-sm font-bold text-center mt-5 text-gray-700 dark:text-gray-200'}>{t('落格智聊机器人构建于 OpenAI GPT 4 模型；')}
                                </div>
                                <div
                                    className={'md:text-lg text-sm font-bold text-center mt-1 text-gray-700 dark:text-gray-200'}>{t('请不要相信模型回答的任何内容，必要时请自行查证内容的真实性；')}
                                </div>
                                <div
                                    className={'md:text-lg text-sm font-bold text-center mt-1 text-gray-700 dark:text-gray-200'}>{t('落格智聊无法对机器人回答的内容负责。')}
                                </div>
                            </div>
                            :
                            <div>
                                <div
                                    className={'md:text-2xl text-lg font-bold text-center md:mt-8 mt-4 dark:text-white'}>{t('欢迎来到落格智聊')}
                                </div>
                                <div
                                    className={'md:text-lg text-sm font-bold text-center mt-5 text-gray-700 dark:text-gray-200'}>{t('请点击左下角登录按钮登录')}
                                </div>
                                <div
                                    className={'md:text-lg text-sm font-bold text-center mt-1 text-gray-700 dark:text-gray-200'}>{t('或者')}
                                </div>
                                <div
                                    className={'md:text-lg text-sm font-bold text-center mt-1 text-gray-700 dark:text-gray-200'}>{t('点击右上角菜单按钮切换为自备 API Key 模式')}
                                </div>
                            </div>
                        }
                        <button
                            className={'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full mx-auto mt-4 md:mt-8 dark:bg-slate-700 dark:hover:bg-slate-600'}
                            onClick={handleHideWelcome}>
                            {t('好')}
                        </button>
                    </div>
                </div>}

                <div id="menuBtn"
                     className={`absolute right-5 ${isMobileVersion() ? 'top-14' : 'top-5'} z-20 ${!hideLeftBar && isMobileVersion() && 'hidden'}`}>
                    <button
                        aria-label="菜单"
                        className="shadow-xl bg-blue-500 md:hover:bg-blue-700 dark:bg-slate-700 md:dark:hover:bg-slate-600 text-white font-bold py-2 px-2 rounded-3xl transition-colors duration-200 ease-in-out"
                        onClick={handleMenuClick}>
                        <Bars4Icon className="w-6 h-6"/>
                    </button>
                </div>
                <div id="switchBtn"
                     className={`absolute ${isMobileVersion() || hideLeftBar ? ' left-1 ' : ' left-1/4 -ml-3'} top-14 z-20 `}>
                    <button
                        aria-label="开关侧栏"
                        className="shadow-xl md:hover:bg-white bg-gray-200 dark:bg-slate-700 md:dark:hover:bg-slate-600 text-black dark:text-white py-2 px-2 rounded-3xl transition-colors duration-200 ease-in-out"
                        onClick={handleHideLeftBar}>
                        {hideLeftBar ?
                            <ChevronRightIcon className="w-4 h-4"/> :
                            <ChevronLeftIcon className="w-4 h-4"/>
                        }

                    </button>
                </div>

                <LeftBar className={hideLeftBar && 'hidden'}/>

                {hideLeftBar && isMobileVersion() &&
                    <ChatArea getId={conversationId} setId={setConversationId} flip={{ flip, setFlip }}
                              authUser={authUser}
                              theme={{
                                  isDarkMode,
                                  setDarkMode
                              }}/>
                }

                {isDesktopVersion() &&
                    <ChatArea/>
                }
                {menu && <RightBar/>}
            </GlobalContext.Provider>
        </div>
    );
}

export default App;
