import React, { useContext, useEffect, useState } from 'react';
import { isElectron, isMobileVersion, isStoreVersion, isDesktopVersion, clearVectorDB } from '../Utils';
import { getCurrentUser } from 'aws-amplify/auth';
import { getPriceCategory, getPlaceOrderUrl, setUserTgId, resetAccessToken } from '../Lib/server-api';
import { BuyButton } from '../SmallComponents/BuyButton';
import { Grid } from 'react-loader-spinner';
import SimpleBar from 'simplebar-react';
import ModelSelection from '../SmallComponents/ModelSelection';
import { chatAIModels } from '../Lib/price-models';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { GlobalContext } from '../Contexts/GlobalContext';
import smalltalk from 'smalltalk';
import { CreditCardIcon } from '@heroicons/react/24/solid';

export default function RightBar() {
    const {
        flip,
        setFlip,
        menu,
        config,
        setConfig,
        authUser,
        setAuthUser,
        globalLoading,
        setGlobalLoading,
        userData,
        setUserData,
    } = useContext(GlobalContext);

    const { t } = useTranslation();
    const [tokenInput, setTokenInput] = useState(config.token || '');
    const [workingMode, setWorkingMode] = useState('');
    const [byoToken, setByoToken] = useState(config.workingMode === 'local');
    const [priceCategory, setPriceCategory] = useState(null);
    const [loadingPriceCategory, setLoadingPriceCategory] = useState('');
    const [tgIdInput, setTgIdInput] = useState('');
    const [accessToken, setAccessToken] = useState(null);
    const [showUsage, setShowUsage] = useState(config.showUsage);
    const [autoScroll, setAutoScroll] = useState(config.autoScroll);
    const [globalMemory, setGlobalMemory] = useState(config.globalMemory);
    const [resettingToken, setResettingToken] = useState(false);
    const codeStyle = 'text-blue-700 font-mono font-bold dark:text-blue-300';
    const [selectedModel, setSelectedModel] = useState({
        name: config.modelName,
        feature: '',
    });
    const [showQRCode, setShowQRCode] = useState(false);
    const [notificationLimit, setNotificationLimit] = useState(config.warningLimit);


    useEffect(() => setConfig(prev => {
        const workingMode = byoToken ? 'local' : 'cloud';
        if (prev.workingMode !== workingMode || prev.modelName !== selectedModel.name) {
            return ({ ...prev, ...{ modelName: selectedModel.name, workingMode: byoToken ? 'local' : 'cloud' } });
        } else {
            return prev;
        }
    }), [selectedModel, byoToken, setConfig]);


    useEffect(() => {
        if (userData) {
            setTgIdInput(userData.tgId || '');
            setAccessToken(userData.accessToken || null);
        } else {
            setTgIdInput('');
            setAccessToken(null);
        }
    }, [userData]);

    // here we update user data again when user open right bar
    useEffect(() => {
        if (!menu) return;
        // no need to set it false, because it will be set false when call setAuthUser(user) finish
        getCurrentUser()
            .then((user) => {
                setAuthUser(user);
                getPriceCategory(user).then((data) => {
                    //convert dict into array
                    const priceCategory = Object.keys(data).map((key) => {
                        return { key, value: data[key] };
                    });
                    setPriceCategory(priceCategory);
                });
            })
            .catch(() => {
                setGlobalLoading(false);
                setAuthUser(null);
            });
    }, [menu, setAuthUser, setGlobalLoading]);

    useEffect(() => {
        if (authUser && byoToken) {
            setWorkingMode('自备密钥 | 云端免费中转 Open AI | 不消耗点数\n\n免费使用落格智聊加密中转，落格智聊不会在云端存储用户的密钥。');
        }
        if (authUser && !byoToken) {
            setWorkingMode('落格智聊 | 正常消耗点数');
        }
        if (!authUser && byoToken) {
            setWorkingMode('自备密钥 | 本地直接发送请求到 Open AI | 不消耗点数\n\n请用户注意自身网络安全避免密钥泄露，登录后可免费使用落格智聊加密中转，落格智聊不会在云端存储用户自备密钥。');
        }
        if (!authUser && !byoToken) {
            setWorkingMode('请登录或输入 OpenAI API Key 以使用落格智聊');
        }
    }, [authUser, byoToken]);

    function handleKeyDownForTg(event) {
        if (event.key === 'Enter') {
            if (byoToken) {
                handleTokenSubmit(event);
            } else {
                handleTgIdSubmit(event);
            }
        }
    }

    function handleInputForTg(event) {
        if (byoToken) {
            setTokenInput(event.target.value);
        } else {
            setTgIdInput(event.target.value);
        }
    }

    function handleKeyDownForLimit(event) {
        if (event.key === 'Enter') {
            handleLimitSubmit(event);
        }
    }

    function handleInputForLimit(event) {
        if (event.target.value < 0) {
            setNotificationLimit(0);
        } else {
            setNotificationLimit(Number(event.target.value));
        }
    }

    function handleTokenSubmit(event) {
        event.preventDefault();
        const newConfig = { ...config };
        newConfig.token = tokenInput.length > 0 ? tokenInput : null;
        setConfig(newConfig);
    }

    function handleTgIdSubmit(event) {
        event.preventDefault();
        setUserTgId(authUser, tgIdInput).then((userData) => {
            setUserData(userData);
        });
    }

    function handleLimitSubmit(event) {
        event.preventDefault();
        const newConfig = { ...config };
        newConfig.warningLimit = notificationLimit;
        setConfig(newConfig);
    }

    function handleResetToken(event) {
        event.preventDefault();
        setResettingToken(true);
        if (accessToken) {
            // ask user to confirm
            if (!window.confirm(t('确认要重置 API Key 吗？'))) {
                setResettingToken(false);
                return;
            }
        }
        resetAccessToken(authUser).then((userData) => {
            setUserData(userData);
            setResettingToken(false);
        });
    }

    function handleClearGlobalMemory(event) {
        event.preventDefault();
        // ask user to confirm
        if (!window.confirm(t('确认要清空全局记忆吗？'))) {
            return;
        }
        clearVectorDB();
    }

    function handlePriceClick(event, priceCategory) {
        event.preventDefault();
        setLoadingPriceCategory(priceCategory);
        getPlaceOrderUrl(authUser, priceCategory).then((url) => {
            if (!url) {
                alert('下单失败，请重新下单');
                return;
            }
            // redirect to order page in current tab
            if (isElectron()) {
                window.open(url, '_blank');
            } else {
                window.location.href = url;
            }
        }).catch((error) => {
            setLoadingPriceCategory('');
            alert('下单失败，请重新下单');
            console.error(error);
        }).finally(() => {
            setLoadingPriceCategory('');
        });
    }

    function setUsage(event) {
        event.preventDefault();
        const newConfig = { ...config };
        newConfig.showUsage = !showUsage;
        setConfig(newConfig);
        setShowUsage(!showUsage);
        setFlip(!flip);
    }

    function setScroll(event) {
        event.preventDefault();
        const newConfig = { ...config };
        newConfig.autoScroll = !autoScroll;
        setConfig(newConfig);
        setAutoScroll(!autoScroll);
        setFlip(!flip);
    }

    function setGlobalMemoryFunc(event) {
        event.preventDefault();
        const newConfig = { ...config };
        newConfig.globalMemory = !globalMemory;
        setConfig(newConfig);
        setGlobalMemory(!globalMemory);
        setFlip(!flip);
    }

    function handleCreditCharge(e) {
        e.preventDefault();
        const url = 'https://buy.stripe.com/fZecOS7CW9mG6jucMO';
        smalltalk.alert('⚠️', t('请确保在信用卡付款页面输入账号绑定的 Email 地址，地址不匹配会导致充值失败。'), { cancel: false }).then(() => {
            if (isElectron()) {
                window.open(url, '_blank');
            } else {
                window.open(url, '_blank');
            }
        });
    }

    return (
        <div id="menu"
             className={'configArea absolute origin-top-right bg-gray-200 dark:bg-slate-800 h-full w-full md:w-1/2 md:right-0 '}>
            <SimpleBar forceVisible="y" autoHide={false}
                       className={`h-full ${isMobileVersion() ? 'pt-32' : 'pt-20'} px-5  pb-10`}
                       ariaLabel={'选项设置'}>
                <div className={'flex items-center justify-between'}>
                    <h3 className={'h-3 font-bold z-10 text-gray-700 dark:text-gray-300'}>{t('工作模式')}:</h3>
                    <span className="relative inline-flex shadow-sm rounded-md">
                      <button type="button"
                              onClick={() => setByoToken(false)}
                              className={'relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white dark:bg-black text-sm font-medium hover:bg-gray-50 ' + (!byoToken ? 'text-gray-700 dark:text-gray-300' : 'text-gray-400 dark:text-gray-600')}>{t('落格智聊')}</button>
                      <button type="button"
                              onClick={() => setByoToken(true)}
                              className={'-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white dark:bg-black text-sm font-medium hover:bg-gray-50 ' + (byoToken ? 'text-gray-700 dark:text-gray-300' : 'text-gray-400 dark:text-gray-600')}>{t('自备密钥')}</button>
                    </span>
                </div>

                <div className={'my-5 gap-2 whitespace-pre-wrap text-sm text-gray-700 dark:text-gray-300 pl-5'}>
                    {!globalLoading && !authUser && !byoToken &&
                        <Link to={'/login'} role={'button'}
                              className={'px-2 py-1 rounded dark:bg-blue-800 mr-2 text-white md:hover:dark:bg-blue-700 bg-blue-500 md:hover:bg-blue-600'}>{t('登录')}</Link>
                    }
                    {globalLoading ?
                        <Grid
                            height="20"
                            width="20"
                            color="#1D4ED8"
                            ariaLabel="grid-loading"
                            radius="10.5"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                        : t(workingMode)}

                    <fieldset className="space-y-5">
                        <legend className="sr-only">Point Usage</legend>
                        <div className="relative flex items-start">
                            <div className="flex items-center h-5">
                                <input id="comments" aria-describedby="comments-description" name="comments"
                                       type="checkbox"
                                       className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                       checked={showUsage}
                                       onChange={setUsage}/>
                            </div>
                            <div className="ml-3 text-sm">
                                <label htmlFor="comments" className="font-medium">{t('显示用量')}</label>
                                <p id="comments-description"
                                   className="text-gray-500">{t(`在每条消息下方显示换算为${byoToken ? '美元' : '点数'}的用量。`)}</p>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="space-y-5">
                        <legend className="sr-only">Point Usage</legend>
                        <div className="relative flex items-start">
                            <div className="flex items-center h-5">
                                <input id="comments" aria-describedby="comments-description" name="comments"
                                       type="checkbox"
                                       className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                       checked={autoScroll}
                                       onChange={setScroll}/>
                            </div>
                            <div className="ml-3 text-sm">
                                <label htmlFor="comments" className="font-medium">{t('自动滚动')}</label>
                                <p id="comments-description"
                                   className="text-gray-500">{t(`当 AI 回复消息时，总是自动滚动到最底部。`)}</p>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="space-y-5">
                        <legend className="sr-only">Point Usage</legend>
                        <div className="relative flex items-start">
                            <div className="flex items-center h-5">
                                <input id="comments" aria-describedby="comments-description" name="comments"
                                       type="checkbox"
                                       className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                       checked={globalMemory}
                                       onChange={setGlobalMemoryFunc}/>
                            </div>
                            <div className="ml-3 text-sm">
                                <div className="flex items-center">
                                    <label htmlFor="comments" className="font-medium">{t('全局长期记忆')}</label>
                                    <button
                                        onClick={handleClearGlobalMemory}
                                        className="text-xs font-semibold px-1 py-0 ml-2 rounded transition-colors duration-200 ease-in-out
               dark:bg-blue-600 dark:text-white dark:hover:bg-blue-700 dark:hover:text-white
               bg-blue-50 text-gray-500 hover:bg-blue-100 hover:text-gray-700">
                                        {t('清空记忆')}
                                    </button>
                                </div>
                                <p id="comments-description"
                                   className="text-gray-500">{t('让落格智聊在当前浏览器中记住你，数据本地存储。')}</p>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className={'my-5'}>
                    <div className={'flex items-center gap-6'}>
                        <h3 className={'h-3 font-bold text-gray-700 dark:text-gray-300 mb-1 whitespace-nowrap'}>{t('模型设定')}：</h3>
                        <ModelSelection selected={selectedModel}
                                        disabled={(globalLoading || !userData) && config.workingMode === 'cloud'}
                                        setSelected={setSelectedModel}
                                        options={
                                            Object.keys(chatAIModels)
                                                .map((value) => {
                                                    return {
                                                        name: value,
                                                        feature: isMobileVersion() ? '' : t(chatAIModels[value].feature)
                                                    };
                                                })
                                        }/>
                    </div>
                    {authUser &&
                        <span
                            className={'text-xs text-gray-500 text-center mt-2'}>{t('这些设定在云端同步，包括 Telegram Bot，但不对第三方客户端生效')}</span>}
                </div>

                <div className={`${byoToken ? '' : 'hidden'}`}>
                    <h3 className={'h-3 font-bold text-gray-700 dark:text-gray-300'}>{t('自备密钥')}</h3>
                    <div className={'flex my-5 gap-2 items-center h-10 pl-5'}>
                        <input type="text" className="w-full h-full border border-gray-300 rounded-lg px-2"
                               onInput={handleInputForTg} onKeyDown={handleKeyDownForTg} value={tokenInput}/>
                        {tokenInput !== (config.token && config.token ? config.token : '') &&
                            <button
                                className={'w-20 h-full hover:bg-blue-700 text-white bg-blue-500 font-bold  rounded-lg'}
                                onClick={handleTokenSubmit}>
                                {t('保存')}
                            </button>
                        }
                    </div>
                    <p className={'text-xs text-gray-400 text-center'}>{t('你可以通过注册')} <a
                        href={'https://openai.com'} target={'_blank'}
                        rel={'noreferrer'}>Open
                        AI</a> {t('账号获取 API 密钥')}</p>
                </div>

                <div className={`${!byoToken ? '' : 'hidden'}`}>

                    <div className={'flex my-5 gap-2 items-center justify-between'}>
                        <h3 className={'h-3 font-bold text-gray-700 dark:text-gray-300'}>{t('点数余额')}：</h3>
                        {globalLoading || !userData ?
                            <Grid
                                height="20"
                                width="20"
                                color="#1D4ED8"
                                ariaLabel="grid-loading"
                                radius="10.5"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                            :
                            <span className={'h-3  text-gray-700 dark:text-gray-300'}><code
                                className={codeStyle}>{userData && userData.credit}</code></span>
                        }

                    </div>
                    <p className={'text-xs text-gray-500'}>{t('充值点数用于 GPT 模型计算消耗，不可提现，建议小额充值用完再充。')}</p>
                    <div className={'flex mt-2 gap-2 items-center pl-5 text-gray-700 dark:text-gray-300 text-xs'}>
                        {t('当余额小于')}
                        <input type="number" className="w-14 border border-gray-300 rounded px-1 text-gray-700"
                               disabled={globalLoading || !userData}
                               onInput={handleInputForLimit} onKeyDown={handleKeyDownForLimit}
                               value={globalLoading ? '' : notificationLimit}/>
                        {notificationLimit !== config.warningLimit &&
                            <button
                                className={'w-10 h-full hover:bg-blue-700 text-white bg-blue-500 font-bold  rounded'}
                                onClick={handleLimitSubmit}>
                                {t('保存')}
                            </button>
                        }
                        {t('时发送邮件通知。')}
                    </div>
                    <div className={'flex my-5 gap-2 items-center justify-between'}>
                        {/*绑定 Telegram*/}
                        <h3 className={'h-3 font-bold text-gray-700 dark:text-gray-300 whitespace-nowrap'}>{t('绑定 Telegram')}：</h3>

                        <div className={'flex my-5 gap-2 items-center h-10 pl-5'}>

                            <input type="text" className="w-full h-full border border-gray-300 rounded-lg px-2"
                                   disabled={globalLoading || !userData}
                                   onInput={handleInputForTg} onKeyDown={handleKeyDownForTg}
                                   value={globalLoading ? '' : tgIdInput}/>
                            {tgIdInput !== (userData && userData.tgId ? userData.tgId : '') &&
                                <button
                                    className={'w-20 h-full hover:bg-blue-700 text-white bg-blue-500 font-bold  rounded-lg'}
                                    onClick={handleTgIdSubmit}>
                                    {t('保存')}
                                </button>
                            }
                        </div>
                    </div>
                    {!globalLoading && userData && (userData.tgId || userData.tgId === '') ?
                        <p className={'text-xs text-gray-500'}>{t('已绑定 Telegram ID')}：<code
                            className={codeStyle}>{userData.tgId}</code>{t('，清空输入框并保存可解绑，如果 Bot 依旧不识别，请确保输入的 Telegram ID 正确。')}
                        </p>
                        :
                        <p className={'text-xs text-gray-500'}>{t('与')} <a
                            href={'https://t.me/ChatAI_logcg_bot?start=1'} target={'_blank'}
                            rel="noreferrer"
                            className={'bg-blue-50 font-semibold px-1'}>{t('落格智聊 Bot')}</a> {t('说话，获取你的 Telegram ID，在这里绑定后即可直接在 Telegram 里与落格智聊 AI 交谈，随时随地快速问答。')}
                        </p>}

                    <div className={'flex my-5 gap-2 items-center justify-between'}>
                        {/*API Key*/}
                        <h3 className={'h-3 font-bold text-gray-700 dark:text-gray-300 whitespace-nowrap mb-1'}>API
                            Key：</h3>

                        <div className={'flex grow my-5 gap-2 items-center h-8 pl-5'}>

                            <input type="text" className="w-full h-full rounded-lg px-2"
                                   disabled={globalLoading || !userData}
                                   readOnly={true}
                                   value={accessToken || t('请点击"获取"按钮生成新的 API Key')}/>
                            <button
                                className={'w-20 h-full hover:bg-blue-700 text-white bg-blue-500 font-bold rounded-lg disabled:opacity-50 flex justify-center items-center'}
                                disabled={globalLoading || !userData}
                                onClick={handleResetToken}>
                                {!resettingToken && !globalLoading ?
                                    userData && accessToken ? t('重置') : t('获取')
                                    :
                                    <Grid
                                        height="20"
                                        width="20"
                                        color="#fff"
                                        ariaLabel="grid-loading"
                                        radius="10.5"
                                        wrapperStyle={{}}
                                        wrapperClass="mx-auto"
                                    />
                                }
                            </button>
                        </div>
                    </div>
                    {
                        accessToken && isDesktopVersion() &&
                        (showQRCode ?
                                <QRCode size={256}
                                        className={'mx-auto my-5'}
                                        value={'chatai://addApi?baseDomain=api.chatai.lol&key=' + (accessToken ? accessToken : '')}
                                        viewBox={`0 0 256 256`}/>
                                :
                                <div className={'w-full flex justify-center -mt-6 mb-2'}>
                                    <button
                                        className={'px-2 h-full text-white text-sm bg-blue-700 rounded-lg flex justify-center items-center'}
                                        onClick={() => setShowQRCode(true)}>
                                        {t('使用手机扫码添加 API Key')}
                                    </button>
                                </div>
                        )
                    }
                    {
                        accessToken && isMobileVersion() &&

                        <div className={'w-full flex justify-center -mt-6 mb-2'}>
                            <a className={'px-2 h-full text-white text-sm bg-blue-700 rounded-lg flex justify-center items-center'}
                               href={'chatai://addApi?baseDomain=api.chatai.lol&key=' + (accessToken ? accessToken : '')}
                               rel="noreferrer"
                               target={'_blank'}
                            >
                                {t('点我一键添加 API Key')}
                            </a>
                        </div>

                    }
                    <p className={'text-xs text-gray-500'}>{t('对于任何支持自定义 API 地址的 OpenAI 调试客户端，你都可以使用')}
                        <code
                            className={codeStyle}>API Key</code> {t('以及域名')} <code
                            className={codeStyle}>api.chatai.lol</code> {t('，来使用落格智聊。（目前支持模型')} <code
                            className={codeStyle}>OpenAI</code> {t('和')} <code
                            className={codeStyle}>Claude</code> {t('，注意！为了避免和第三方客户端的设置冲突，此 API 仅计费+转发，包括一切模型参数。）')}
                    </p>
                    <p className={'text-xs text-gray-500 pt-4'}>{t('落格智聊 API 支持 Embedding，每 1000')} <code
                        className={codeStyle}>token</code> {t('消耗') + ' 0.004 ' + t('点数。（目前支持模型')} <code
                        className={codeStyle}>text-embedding-ada-002</code> <code
                        className={codeStyle}>text-embedding-3-small</code> <code
                        className={codeStyle}>text-embedding-3-large</code>）
                    </p>
                    <p className={'text-xs text-gray-500 pt-4'}>{t('落格智聊 API 支持 Audio，每 1 分钟')} {t('消耗') + ' 0.06 ' + t('点数。（目前支持模型')}
                        <code className={codeStyle}>whisper-1</code> <code className={codeStyle}>tts-1</code> <code
                            className={codeStyle}>tts-1-hd</code>）</p>
                    <div className={'h-1 my-5 shadow border-solid w-full'}></div>
                    <div className={'flex my-5 gap-2 items-center justify-between'}>
                        <h3 className={'h3 font-bold text-gray-700 dark:text-gray-300 my-5 '}>{t('单价')}：</h3>
                        <div>
                            <span className={'dark:text-white'}>{t('请移步')}</span>
                            <button className={'text-blue-500 mx-2'}
                                    onClick={() => window.open('https://im.logcg.com/chat-ai/pricing', '_blank')}>{t('单价表')}</button>
                            <span className={'dark:text-white'}>{t('查看详情。')}</span>
                        </div>
                    </div>

                    <h3 className={'h3 font-bold text-gray-700 dark:text-gray-300 my-5'}>{t('费用计算')}：</h3>
                    <span className={'text-sm dark:text-white'}>
                {t('落格智聊使用多种 API 进行计算，当前模型为')} <code
                        className={codeStyle}>{selectedModel.name.toUpperCase()}</code>.
                    <br/>
                        {t('。用户输入的内容以及模型推断内容都会被计入点数消耗，计算单位是文字编码后的') + ' '}
                        <code className={codeStyle}>token</code> {t('数量。')}
                        <br/>
                        {t('通常来说，')}100 <code
                        className={codeStyle}>token</code> {t('能编码 40 - 50 个汉字，或者 70 - 75 个英文单词。')}
                        <br/>
                        {t('另外，为了让模型能够进行正确计算，落格智聊会在整体内容前加上大概')} 150 <code
                        className={codeStyle}>token</code> {t('的提示词，还有当前会话的上下文，这些内容都会在每次发送请求时被计算在内。')}
                        <br/>
                        {t('注意：实际模型处理的')} <code
                        className={codeStyle}>token</code> {t('数量以 ChatAI 服务器返回结果为准。')}
            </span>
                    <h3 className={'h3 font-bold text-gray-700 dark:text-gray-300 my-5'}>{t('点数充值')}</h3>
                    {isStoreVersion() ?
                        <span className={'text-sm dark:text-white'}>
                    {t('商店版客户端暂时不支持点数充值。')}
                     </span>
                        :
                        <ul className={'px-10'}>
                            {
                                priceCategory ?
                                    priceCategory.map((item, index) => {
                                        return (<li className={'my-5'} key={index}>
                                            <BuyButton onClick={(e) => handlePriceClick(e, item.key)}
                                                       priceCat={item.key}
                                                       priceValue={item.value}
                                                       loading={loadingPriceCategory === item.key}></BuyButton>
                                        </li>);
                                    })
                                    :
                                    <Grid
                                        height="50"
                                        width="50"
                                        color="#3B82F6"
                                        ariaLabel="grid-loading"
                                        radius="10.5"
                                        wrapperStyle={{}}
                                        wrapperClass="justify-center"
                                    />
                            }
                            {priceCategory &&
                                <li className={'my-5'} key={priceCategory.length}>
                                    <button
                                        className="h-16 w-full rounded-xl border-2 border-blue-400 dark:border-slate-400 dark:text-white py-2 md:hover:border-yellow-500 hover:bg-yellow-50 transition-transform shadow-sm duration-500 ease-in-out transform md:hover:-translate-y-1 md:hover:scale-105 md:hover:text-yellow-600 md:hover:dark:bg-yellow-400 md:hover:dark:text-yellow-900  items-center justify-center flex flex-col"
                                        onClick={handleCreditCharge}>

                                        <div className={'flex gap-4 items-center'}>
                                            <CreditCardIcon className="w-8 h-8"/>
                                            <div>
                                                <div className={'text-sm'}>{t('充值金额')} $<span
                                                    className={''}>10</span> {t('USD')} ({t('信用卡')})
                                                </div>
                                                <div>{t('获得点数')} <span className={codeStyle}>66</span> {t('点')}
                                                </div>
                                            </div>
                                        </div>

                                    </button>
                                </li>
                            }
                        </ul>
                    }

                </div>
            </SimpleBar>
        </div>
    );
}
