import OpenAI from 'openai';
import { chatAIModels, openAIModels } from './price-models';
import GPT3Tokenizer from 'gpt3-tokenizer';
import { getServerToken } from './server-api';
import { genHistoryForOpenAI, getServerAPI } from '../Utils';

const host = process.env.REACT_APP_D === 'true' ? 'http://127.0.0.1:5000' : 'https://api.chatai.lol';
let chatAIRedirectEndpoint = `${host}/v1/redirect`;
let chatAIAskEndpoint = `${host}/v1/chat-ai`;
let chatAIEmbeddingEndpoint = `${host}/v1/embeddings`;
let chatAIConvSummaryEndpoint = `${host}/v1/conversation-summary`;
if (process.env.REACT_APP_D !== 'true') {
    getServerAPI(host).then((serverAPI) => {
        if (serverAPI) {
            chatAIRedirectEndpoint = serverAPI + '/v1/redirect';
            chatAIAskEndpoint = serverAPI + '/v1/chat-ai';
            chatAIEmbeddingEndpoint = serverAPI + '/v1/embeddings';
            chatAIConvSummaryEndpoint = serverAPI + '/v1/conversation-summary';
        }
    });
}

const tokenizer = new GPT3Tokenizer({ type: 'gpt3' });

function callbackProcessNonStream(res, model, callback) {
    let str = res.choices[0].message.content;
    str = str.replace(/^\s+|\s+$/g, '');
    let promptTokens = res.usage.prompt_tokens;
    let completionTokens = res.usage.completion_tokens;
    let cost = promptTokens / model.promptTokenPerDollar + completionTokens / model.completionTokenPerDollar;

    callback && callback(str, cost);
}

async function callbackProcessStream(res, model, baseToken, callback) {
    let tokenCount = 0;
    const decoder = new TextDecoder('utf-8');
    const reader = res.body.getReader();
    let done, value;
    while (!done) {
        try {
            ({ value, done } = await reader.read());
        } catch (e) {
            console.log(e);
            if (e.name === 'AbortError') {
                let cost = (baseToken / model.promptTokenPerDollar) + (tokenCount / model.completionTokenPerDollar);
                callback && callback(null, null, cost, null, { canContinue: true });
            } else {
                callback && callback(null, null, null, e);
            }

            break;
        }

        const dataString = decoder.decode(value);
        const lines = dataString.split('\n').filter(line => line.startsWith('data: '));
        for (const line of lines) {
            let d = line.slice(6);
            if (d === '[DONE]') continue;
            tokenCount++;
            try {
                let d_obj = JSON.parse(d);
                if (d_obj.choices.length === 0) continue

                if (d_obj.choices[0].finish_reason !== null && d_obj.choices[0].finish_reason === 'start') {
                    // do nothing
                } else if (d_obj.choices[0].finish_reason !== null && d_obj.choices[0].finish_reason !== 'start') {
                    let promptTokens = baseToken;
                    let completionTokens = tokenCount;
                    const fact = d_obj.fact_check_content || null;
                    const webContents = d_obj.web_contents || null;
                    const reasoningContent = d_obj.reasoning_content || null;
                    const canContinue = d_obj.choices[0].finish_reason !== 'stop';
                    if (d_obj.prompt_tokens) {
                        promptTokens = d_obj.prompt_tokens;
                    }
                    if (d_obj.completion_tokens) {
                        completionTokens = d_obj.completion_tokens;
                    }
                    let cost = (promptTokens / model.promptTokenPerDollar) + (completionTokens / model.completionTokenPerDollar);
                    callback && callback(null, null, cost, null, { fact, webContents, canContinue, reasoningContent });
                    break;
                } else {
                    let content = ''
                    let reasoningContent = null
                    if (d_obj.choices[0].delta.content !== null
                        && d_obj.choices[0].delta.content !== ''
                        && d_obj.choices[0].delta.content !== undefined) {
                        content = d_obj.choices[0].delta.content;
                    }
                    if (d_obj.choices[0].delta.reasoning_content !== null
                        && d_obj.choices[0].delta.reasoning_content !== ''
                        && d_obj.choices[0].delta.reasoning_content !== undefined) {
                        reasoningContent = d_obj.choices[0].delta.reasoning_content;
                    }

                    if (content || reasoningContent) {
                        callback && callback(content, reasoningContent, null);
                    }
                }
            } catch (e) {
                console.log(d);
                console.log(e);
            }
        }
    }
}

function requestToOpenAI(model, promptOption, axiosOptions, token, isStream, callback) {
    // byo mode not support streaming anymore.
    const openai = new OpenAI({ apiKey: token, dangerouslyAllowBrowser: true });
    openai.chat.completions.create(promptOption, axiosOptions).then(res => {
        if (!isStream) {
            callbackProcessNonStream(res, model, callback);
        } else {
            // not used
            let tokenCount = 0;
            res.data.on('data', chunk => {
                let s = chunk.toString();
                let s_arr = s.split('data: ');
                let d = s_arr[1];
                if (d.startsWith('{')) {
                    tokenCount++;
                    let d_obj = JSON.parse(d);
                    callback && callback(d_obj.choices[0].delta.content, null);
                } else {
                    let bytes = (new TextEncoder().encode(promptOption.prompt)).length;
                    let promptTokens = parseInt((bytes / 4).toFixed(0));
                    let cost = (tokenCount + promptTokens) / model.oneDollarToken;
                    callback && callback(null, cost);
                }
            });
        }
    }).catch(err => {
        callback && callback(null, null, err);
    });
}

/**
 *
 * @param model model name ['davinci', 'curie', 'babbage', 'ada'] chatAIModels
 * @param promptOption
 * @param token
 * @param accessToken
 * @param callback
 * @param signal
 */
function redirectToOpenAI(model, promptOption, token, accessToken, callback, signal) {
    fetch(chatAIRedirectEndpoint, {
        method: 'POST',
        signal: signal,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
            ...promptOption, 'user_token': token
        })
    })
        .then(res => {
            const txt = promptOption.prompt.map(p => `${p.role}:${p.content}`).join('\n');
            const encoded = tokenizer.encode(txt);
            const baseToken = encoded.text.length;
            callbackProcessStream(res, model, baseToken, callback);
        })
        .catch(e => {
            if (e.name === 'AbortError') {
                // abort error could happens here when user abort the fetch before it starts streaming
                callback && callback(null, null, null, null);
            } else {
                callback && callback(null, null, e, null);
            }
        });
}

/**
 *
 * @param {string} m - model name ['davinci', 'curie', 'babbage', 'ada'] openAIModels
 * @param {object} options  - model options
 * @param {string} openAIToken
 * @param {function} cb - callback function
 */
function ask(m, options, openAIToken, cb) {
    const model = openAIModels[m];
    options.model = model.name;
    requestToOpenAI(model, options, {}, openAIToken, false, cb);
}

/**
 *
 * @param {string} m - model name ['davinci', 'curie', 'babbage', 'ada'] openAIModels
 * @param {object} options  - model options
 * @param {string} openAIToken
 * @param {function} cb - callback function
 * @param signal
 */
function askRedirected(m, options, openAIToken, cb, signal) {
    const model = openAIModels[m];
    options.model = model.name;
    options.stream = true;
    getServerToken().then(accessToken => {
        redirectToOpenAI(model, options, openAIToken, accessToken, cb, signal);
    });
}

/**
 *
 * @param m
 * @param {[object]} history
 * @param {text: string, reasoningContent: string, cost: number, err: Error, extras: Object} callback
 * @param {AbortController} signal
 * @param {{globalMemory:boolean}} config
 * @param {{reasoningEffort: string}} reasoningEffort - low, medium, high
 */
function askChatAI(m, history, callback, signal, config, reasoningEffort) {
    const model = chatAIModels[m];
    const data = {
        chat_history: [],
        model: model.name,
        reasoning_effort: reasoningEffort,
    };
    genHistoryForOpenAI(history, config.globalMemory)
        .then(histories => {
            data.chat_history = histories;
        })
        .then(() => getServerToken())
        .then((accessToken) => fetch(chatAIAskEndpoint, {
            timeout: 30000,
            method: 'POST',
            signal: signal,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(data)
        }))
        .then(res => {
            if (res.ok) {
                callbackProcessStream(res, model, 0, callback);
            } else {
                throw new Error(res.status);
            }
        })
        .catch(e => {
            if (e.name === 'AbortError') {
                // abort error could happens here when user abort the fetch before it starts streaming
                callback && callback(null, null, null, null, null);
            } else {
                callback && callback(null, null, null, e, null);
            }
        });

}

function getEmbedding(input) {
    const data = {
        input,
        'model': 'text-embedding-3-small',
        'encoding_format': 'float'
    };
    return getServerToken()
        .then((accessToken) => fetch(chatAIEmbeddingEndpoint, {
            timeout: 30000,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(data)
        })).then(res => res.json())
        .then(res => {
            // console.log(res);
            return res.data;
        });
}

function summaryConversationTitle(conversation, config) {
    const data = {
        chat_history: [],
    };
    return genHistoryForOpenAI(conversation, config.globalMemory)
        .then(histories => {
            data.chat_history = histories;
        })
        .then(() => getServerToken()).then((accessToken) => fetch(chatAIConvSummaryEndpoint, {
            timeout: 30000,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(data)
        }))
        .then(res => res.json())
        .then(res => {
            // console.log(res);
            return res.choices[0].message.content;
        });
}


export { ask, askChatAI, askRedirected, getEmbedding, summaryConversationTitle };

