import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { GlobalContext } from '../Contexts/GlobalContext';
import { Grid } from 'react-loader-spinner';
import { getConversionById } from '../Utils';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import smalltalk from 'smalltalk';

function MobileToolbar() {
    const {
        conversationId,
        flip,
        authUser,
        globalLoading,
        conversationIdList,
        startNewConversation
    } = useContext(GlobalContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [currentTitle, setCurrentTitle] = useState('');

    useEffect(() => {
        if (!conversationIdList.includes(conversationId)) {
            return;
        }
        let title = t('新会话');
        getConversionById(conversationId).then(conversation => {
            if (conversation) {
                title = conversation.title;
            }
            setCurrentTitle(`${t('会话')}: ${title}...`);
        });
    }, [conversationId, flip]); // eslint-disable-line react-hooks/exhaustive-deps

    function handleNewConversation() {
        smalltalk.alert(t('开启新会话'), t('你可以在左侧栏切换其他会话'), {
            buttons: {
                ok: t('新建'),
                cancel: t('取消')
            }
        })
        .then(() => startNewConversation())
        .catch(() => {
            console.log('User canceled new conversation creation');
        });
    }

    return (
        <div className={''}>
            <hr className={'border-gray-500'}/>
            {globalLoading ?
                <div className={'my-2 bg-blue-100 dark:bg-slate-800 px-2 rounded py-2 flex items-center justify-center dark:text-gray-500'}>
                    <Grid
                        height="16"
                        width="16"
                        color="#1D4ED8"
                        ariaLabel="grid-loading"
                        radius="10.5"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
                : <div className={'my-2 bg-blue-100 dark:bg-slate-800 px-2 rounded py-1 flex items-center justify-between dark:text-gray-500'}>
                    {authUser &&
                        <button aria-label="新会话" disabled={globalLoading}
                             className="my-1 mr-2 transition-colors duration-200 ease-in-out bg-blue-500 hover:bg-blue-700 dark:bg-slate-700 dark:hover:bg-slate-600 text-white font-bold py-2 px-4 rounded  disabled:opacity-50"
                             onClick={handleNewConversation}>
                        <PlusCircleIcon className="w-5 h-5"/>
                    </button>}
                    <div className="flex items-center">

                        <span>{currentTitle}</span>
                    </div>
                    {!authUser &&
                        <button className={'my-1 bg-blue-500 dark:bg-slate-600 px-2 rounded py-1 text-white'} onClick={() => navigate('/login')}>
                            {t('登录')}
                        </button>
                    }
                </div>
            }
        </div>
    );
}

export default React.memo(MobileToolbar, (prevProps, nextProps) => {
    return prevProps.getId === nextProps.getId && prevProps.flip === nextProps.flip && prevProps.authUser === nextProps.authUser;
});
