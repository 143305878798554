import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import React from 'react';
import {
    getChatItem,
    genHistoryForOpenAI,
    isMobileVersion,
    isClient,
    isDesktopVersion,
    mergeStrings,
    copyElementToClipboard,
    parseHtml, getConversionById, setConversation, nextConversationId, getBase64Size,
} from '../Utils';
import {ask, askChatAI, askRedirected, summaryConversationTitle} from '../Lib/open-ai';
import MobileToolbar from '../SmallComponents/MobileToolbar';
import {isFirefox} from 'react-device-detect';
import {Grid} from 'react-loader-spinner';
import hljs from 'highlight.js';
import MarkdownIt from 'markdown-it';
import SimpleBar from 'simplebar-react';
import smalltalk from 'smalltalk';
import {sendConversationToEmail} from '../Lib/server-api';
import {
    ArrowPathIcon,
    StopCircleIcon,
    ArrowsUpDownIcon,
    PhotoIcon,
    PaperAirplaneIcon,
    XMarkIcon,
} from '@heroicons/react/24/solid';
import {useTranslation} from 'react-i18next';
import ChatItem from '../SmallComponents/ChatItem';
import {GlobalContext} from '../Contexts/GlobalContext';
import {chatAIModels} from '../Lib/price-models';
import InlineDropdown from '../SmallComponents/InlineDropdown';
import ImageModal from "../SmallComponents/ImageModal";
import { isHeic, heicTo } from "heic-to"


function beautifulLinks(md) {
    const defaultRenderRule = md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
    };

    md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
        tokens[idx].attrPush(['class', 'text-blue-500  hover:text-blue-700 inline-flex items-center']);
        return defaultRenderRule(tokens, idx, options, env, self);
    };
}

function beautifulBlockquote(md) {
    const defaultRender = md.renderer.rules.blockquote_open || function (tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
    };
    md.renderer.rules.blockquote_open = function (tokens, idx, options, env, self) {
        tokens[idx].attrPush(['class', 'bg-gray-100 dark:bg-gray-900 p-3 mb-2 rounded text-gray-700 dark:text-gray-300']);
        return defaultRender(tokens, idx, options, env, self);
    };
}

function beautifulCode(md) {
    const defaultRender = md.renderer.rules.code_inline || function (tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
    };
    md.renderer.rules.code_inline = function (tokens, idx, options, env, self) {
        tokens[idx].attrPush(['class', 'bg-gray-100 dark:bg-gray-900 px-2 py-1 mx-1 rounded text-gray-700 dark:text-gray-300']);
        return defaultRender(tokens, idx, options, env, self);
    };
}

export default function ChatArea() {
    const {
        conversationId: getId,
        setConversationId: setId,
        setConversationIdList,
        flip,
        setFlip,
        authUser,
        setInputCache,
        setImageCache,
        config,
        conversationIdList
    } = useContext(GlobalContext);
    const {t} = useTranslation();
    const [showMDIndexSet, setShowMDIndexSet] = useState(new Set([]));
    const [chatHistory, setChatHistory] = useState([]);
    const conversationRef = useRef(null);
    const [chatInputEditable, setChatInputEditable] = useState(true);
    const [emailChecked, setEmailChecked] = useState(false);
    const chatInputRef = useRef(null);
    const scrollBottomDivRef = useRef(null);
    const streamControllerRef = useRef(null);

    const mdRender = useRef(new MarkdownIt({
        html: false,
        linkify: false,
        breaks: true, // make single line break to be a line break not a space
        typographer: true,
        highlight: function (str, lang) {
            if (lang && hljs.getLanguage(lang)) {
                try {
                    return '<pre class="hljs p-3 mb-2 rounded break-all"><code>' +
                        hljs.highlight(str, {language: lang, ignoreIllegals: true}).value +
                        '</code></pre>';
                } catch (__) {
                }
            }
            // Return an un-highlighted but styled version of the code for blocks without a language.
            return `<pre class="hljs p-3 mb-2 rounded break-all"><code>${mdRender.current.utils.escapeHtml(str)}</code></pre>`;
        }
    }));
    useEffect(() => {
        mdRender.current.renderer.rules.table_open = function () {
            return '<table class="table table-auto my-2 rounded">';
        };
        mdRender.current.use(require('markdown-it-texmath'), {
            engine: require('katex'),
            delimiters: 'dollars',
            katexOptions: {
                macros: {'\\RR': '\\mathbb{R}'},
                throwOnError: false,
                output: 'mathml',
            }
        });
        mdRender.current.use(require('markdown-it-sup'));
        mdRender.current.use(require('markdown-it-sub'));
        mdRender.current.use(beautifulLinks);
        mdRender.current.use(beautifulBlockquote);
        mdRender.current.use(beautifulCode);
    }, []);

    const timeout_streaming = useRef(null);
    const [requesting, setRequesting] = useState(false);
    const [composition, setComposition] = useState(false);
    const [streamContent, setStreamContent] = useState('');
    const [reasoningContent, setReasoningContent] = useState('');
    const [showButtonIndexMobile, setShowButtonIndexMobile] = useState(-1);
    const [canContinue, setCanContinue] = useState(false);
    const [selectedModel, setSelectedModel] = useState('default');
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedImageChatArea, setSelectedImageChatArea] = useState(null);
    const [modelSupportImages, setModelSupportImages] = useState(false);
    const [needDetailFor4o, setNeedDetailFor4o] = useState(false);
    const [imageDetail, setImageDetail] = useState('low');
    const [modelSupportReasoning, setModelSupportReasoning] = useState(false);
    const [modelReasoningEffort, setModelReasoningEffort] = useState('medium'); // low, medium, high
    const streamCache = useRef('')
    const reasoningCache = useRef('')

    useEffect(() => {
        setChatInputEditable((config.token || authUser) && !requesting);
    }, [authUser, requesting, config.token]);

    useEffect(() => {

        if (!requesting && chatInputRef.current && !isMobileVersion()) {
            //delay for 0.5s to avoid focus on mobile
            setTimeout(() => {
                if (!chatInputRef.current) return;
                chatInputRef.current?.focus();
            }, 500);
        }
    }, [requesting]);

    useEffect(() => {
        if (!config.autoScroll) return;
        if (!scrollBottomDivRef.current) return;
        // debounce call
        if (timeout_streaming.current) clearTimeout(timeout_streaming.current);
        timeout_streaming.current = setTimeout(() => {
            scrollBottomDivRef.current?.scrollIntoView({behavior: 'smooth'});
        }, 50);
    }, [config, streamContent]);

    /**
     * when conversation id changes, update chat history and other states
     */
    useEffect(() => {
        if (getId === -1) {
            setChatHistory([]);
            if (chatInputRef.current) {
                chatInputRef.current.innerText = '';
            }
            setInputCache({id: getId, text: ''});
            setImageCache({id: getId, images: []});
            setSelectedImages([]);
            setSelectedModel('default');
            setImageDetail('low');
            setModelReasoningEffort('medium');
        } else {
            getConversionById(getId).then((conversation) => {
                if (conversation.model && chatAIModels[conversation.model]) {
                    setSelectedModel(conversation.model);
                } else {
                    setSelectedModel('default');
                }
                if (conversation.imageDetail) {
                    setImageDetail(conversation.imageDetail);
                }
                if (conversation.reasoningEffort) {
                    setModelReasoningEffort(conversation.reasoningEffort);
                }
                setChatHistory(conversation.messages);
                conversationRef.current = conversation;
                if (chatInputRef.current) {
                    chatInputRef.current.innerText = conversation.inputCache || '';
                }
                setSelectedImages((conversation.imageCache || []).map(i => ({
                    id: Math.random(),
                    src: i,
                    isRemoving: false
                })))
                setInputCache({id: getId, text: conversation.inputCache || ''});
                setImageCache({id: getId, images: conversation.imageCache || []});
            });
        }
        setStreamContent('');
        streamCache.current = ''
        setEmailChecked(false);
        setShowButtonIndexMobile(-1);
        setShowMDIndexSet(new Set([]));

        if (isDesktopVersion()) {
            // autofocus on input box if it is desktop version
            chatInputRef.current.focus();
        }
    }, [getId, setInputCache, flip, config.modelName, setImageCache]);

    // set if model support vision
    useEffect(() => {
        if (selectedModel !== 'default') {
            // if the model name contains claude or 4o, set modelSupportImages to true
            setModelSupportImages(!!selectedModel.match(/claude|4o|o1(?!-)|vision|gpt-4.5|gemini-2.0|gemini-2.5/));
            setNeedDetailFor4o(!selectedModel.includes('claude') );

            setModelSupportReasoning(!!selectedModel.match(/o3|o1|7-sonnet/))
        } else {
            setModelSupportImages(!!config.modelName.match(/claude|4o|o1(?!-)|vision|gpt-4.5|gemini-2.0|gemini-2.5/));
            setNeedDetailFor4o(!config.modelName.includes('claude'));

            setModelSupportReasoning(!!config.modelName.match(/o3|o1|7-sonnet/));
        }
    }, [selectedModel, config.modelName]);

    // set image cache
    useEffect(() => {
        setImageCache({id: getId, images: selectedImages.map(i => i.src)});
    }, [getId, selectedImages, setImageCache])

    // 自动滚动
    useEffect(() => {
        if (!config.autoScroll) return;
        if (chatHistory.length > 0) {
            scrollBottomDivRef.current.scrollIntoView({behavior: 'auto'});
        }
    }, [chatHistory, config]);

    // 处理复制粘贴
    useEffect(() => {
        if (chatInputRef.current) {
            chatInputRef.current.addEventListener('compositionstart', () => {
                setComposition(true);
            });
            chatInputRef.current.addEventListener('compositionend', () => {
                setComposition(false);
            });
            chatInputRef.current.addEventListener('paste', (e) => {
                e.preventDefault();

                // 处理文本粘贴
                const text = (e.originalEvent || e).clipboardData.getData('text/plain');
                if (text) { // resolved cursor move to top after paste
                    // 在光标位置插入文本
                    const selection = window.getSelection();
                    if (selection.rangeCount) {
                        const range = selection.getRangeAt(0);
                        range.deleteContents();
                        const textNode = document.createTextNode(text);
                        range.insertNode(textNode);

                        // 将光标移动到插入文本的末尾
                        range.setStartAfter(textNode);
                        range.setEndAfter(textNode);
                        selection.removeAllRanges();
                        selection.addRange(range);
                    }
                }

                // 处理图片粘贴
                const items = (e.clipboardData || e.originalEvent.clipboardData).items;
                for (let index in items) {
                    const item = items[index];
                    if (item.kind === 'file') {
                        const blob = item.getAsFile();
                        const reader = new FileReader();
                        reader.onload = function (event) {
                            setSelectedImages(prevImages => [...prevImages, {
                                id: Math.random(),
                                src: event.target.result,
                                isRemoving: false
                            }]);
                        };
                        reader.readAsDataURL(blob);
                    }
                }
            });
        }
    }, []);

    useEffect(() => {
        if (selectedImages.length > 5) {
            // if selected images exceed 5, keep only the last 5
            setSelectedImages(selectedImages.slice(-5));
        }
    }, [selectedImages]);

    function handleChatInputKeyDown(e) {
        if (e.key === 'Enter' && !e.shiftKey && !composition && !isMobileVersion()) {
            e.preventDefault();
            handleChatSubmit(e);
        }
    }

    function handleChatInput() {
        setInputCache({id: getId, text: chatInputRef.current.innerText});
    }

    const handleAskCallbackStream = useCallback(async (newHistory, conversation, mode, text, reasoningContent, cost, err, extras)=>{
        let updatedHistory;
        if (streamCache.current === '[--Loading--]') {
            streamCache.current = '';
            reasoningCache.current = '';
        }
        if (err) {
            if (err.message === '402') {
                updatedHistory = [...newHistory, getChatItem('SYSTEM', t('你的余额不足，请充值后再试。'))];
            } else if (err.message === '429') {
                updatedHistory = [...newHistory, getChatItem('SYSTEM', t('模型过于忙碌，请先切换 GPT 3.5 Turbo 模型试试。'))];
            } else if (err.message === '401') {
                updatedHistory = [...newHistory, getChatItem('SYSTEM', t('登录超时或账号未验证，请刷新页面重试。'))];
            } else if (err.message === '502') {
                updatedHistory = [...newHistory, getChatItem('SYSTEM', t('Open AI API 出错了，请再发一次试试。'))];
            } else {
                updatedHistory = [...newHistory, getChatItem('SYSTEM', `${t('似乎哪里出错了，请刷新页面试试。')} ${err}`)];
            }
        } else if (text) {
            streamCache.current += text
            setStreamContent(streamCache.current);
        } else if (reasoningContent) {
            reasoningCache.current += reasoningContent
            setReasoningContent(reasoningCache.current);
        }

        if (text === null && cost && streamCache.current && streamCache.current.length > 0) {
            if (newHistory[newHistory.length - 1].continued) {
                newHistory.pop();
                setCanContinue(false);
            }
            if (!err) {
                const factContent = (extras && extras.fact) || null;
                const factWebContents = (extras && extras.webContents) || null;
                const showContinueButton = (extras && extras.canContinue) || false;
                const reasoningContent = (extras && extras.reasoningContent) || null;
                setCanContinue(showContinueButton);

                const newItem = getChatItem('AI', streamCache.current, mode, cost, factContent, factWebContents, false, [], 'low', reasoningContent);
                streamCache.current = ''
                reasoningCache.current = ''
                if (newHistory[newHistory.length - 1].sender === 'AI') {
                    const mergingItem = newHistory.pop();
                    newItem.content = mergeStrings(mergingItem.content, newItem.content);
                    newItem.cost = mergingItem.cost + newItem.cost;
                    newItem.fact = mergingItem.fact || newItem.fact;
                    newItem.webContents = mergingItem.webContents || newItem.webContents;
                    newItem.mode = mergingItem.mode || newItem.mode;
                    newItem.reasoningContent = mergingItem.reasoningContent || newItem.reasoningContent;
                }
                updatedHistory = [...newHistory, newItem];
            }
            setChatHistory(updatedHistory);
            if (!err) {
                conversation.messages = updatedHistory;
                if (conversation.title === t('新会话')) {
                    await summaryConversationTitle(updatedHistory, config).then(s=>JSON.parse(s)).then(async s => {
                        if (s.title) {
                            conversation.title = s.title;
                        } else {
                            conversation.title = `${updatedHistory[0].content.substring(0, 25)}`;
                        }
                    })
                }
                setConversation(conversation, config).then(() => {
                    if (getId === -1) {
                        setId(conversation.id); // useful when user used default new conversation, which the id is -1
                    }
                    setFlip(!flip);
                    // here we flipt, it will trigger update of the conversationStore
                });
            }
        }
        if (text === null) {
            setStreamContent('');
            streamCache.current = ''
            setRequesting(false);
        }
        if (reasoningContent === null) {
            setReasoningContent('');
            reasoningCache.current = ''
        }

    }, [config, flip, getId, setFlip, setId, t])

    function handleAskCallback(newHistory, conversation, mode, text, cost, err) {
        let updatedHistory;
        if (err) {
            console.log(err);
            if (err.message === '402') {
                updatedHistory = [...newHistory, getChatItem('SYSTEM', t('你的余额不足，请充值后再试。'))];
            } else if (err.message === '429') {
                updatedHistory = [...newHistory, getChatItem('SYSTEM', t('模型过于忙碌，请先切换 GPT 3.5 Turbo 模型试试。'))];
            } else if (err.message === '401') {
                updatedHistory = [...newHistory, getChatItem('SYSTEM', t('登录超时或账号未验证，请刷新页面重试。'))];
            } else {
                updatedHistory = [...newHistory, getChatItem('SYSTEM', t('似乎哪里出错了，请刷新页面试试。'))];
            }
        } else {
            updatedHistory = [...newHistory, getChatItem('AI', text, mode, cost)];
        }
        setChatHistory(updatedHistory);
        conversation.messages = updatedHistory;
        if (conversation.title === t('新会话')) {
            conversation.title = `${updatedHistory[0].content.substring(0, 25)}`;
        }
        setConversation(conversation, config).then(() => {
            setRequesting(false);
            if (getId === -1) {
                setId(conversation.id); // useful when user used default new conversation, which the id is -1
            }
            setFlip(!flip);
        });
    }

    async function askOpenAI(newHistory, conversation) {
        const his = await genHistoryForOpenAI(newHistory);
        const composedHistory = [{
            'role': 'system',
            'content': config.prompt
        }].concat(his);
        if (authUser) {
            streamCache.current = '[--Loading--]';
            streamControllerRef.current = new AbortController();
            askRedirected(config.modelName, {
                    prompt: composedHistory,
                    temperature: 0.9,
                    max_tokens: 500,
                    top_p: 1,
                    frequency_penalty: 0,
                    presence_penalty: 0.6,
                    stream: true,
                },
                config.token,
                (text, cost, err) => handleAskCallbackStream(newHistory, conversation, 'local', text, cost, err),
                streamControllerRef.current.signal
            );
        } else {
            ask(config.modelName, {
                    messages: composedHistory,
                    temperature: 0.9,
                    max_tokens: 500,
                    top_p: 1,
                    frequency_penalty: 0,
                    presence_penalty: 0.6,
                    stream: false,
                }, config.token, (text, cost, err) => handleAskCallback(newHistory, conversation, 'local', text, cost, err)
            );
        }

    }

    function _askChatAI(newHistory, conversation) {
        streamCache.current = '[--Loading--]';
        streamControllerRef.current = new AbortController();
        askChatAI(
            conversation.model || config.modelName,
            newHistory,
            (text, reasoningContent, cost, err, extras) => handleAskCallbackStream(newHistory, conversation, 'cloud', text, reasoningContent, cost, err, extras),
            streamControllerRef.current.signal,
            config,
            modelReasoningEffort
        );
    }

    //handle chat submit
    async function handleChatSubmit(event) {
        event.preventDefault();
        const chatInput = chatInputRef.current.innerText;
        if (chatInput === '' && selectedImages.length === 0) {
            return;
        }
        if (requesting) {
            return;
        }
        const imageBase64s = selectedImages.map(image => image.src);
        // remove selected images with animation, first we set it removing
        setSelectedImages(prevImages => prevImages.map(img => ({...img, isRemoving: true})));
        setTimeout(() => {
            // then we remove it after 300ms
            setSelectedImages([]);
        }, 300);
        setInputCache({id: getId, text: ''});
        setImageCache({id: getId, images: []});
        if (conversationRef.current) {
            conversationRef.current.inputCache = '';
            conversationRef.current.imageCache = [];
        }
        const oldHistory = chatHistory;
        if (oldHistory.length > 1) {
            const lastOne = oldHistory[oldHistory.length - 1];
            if (lastOne.sender === 'SYSTEM') {
                oldHistory.pop();
            }
        }
        const newHistory = [...oldHistory, getChatItem('Human', chatInput, '', 0, '', [], false, imageBase64s, imageDetail)];
        setChatHistory(newHistory);
        // set input element to empty
        chatInputRef.current.innerText = '';
        if (conversationIdList.length === 0 || getId === -1) {
            const nextId = nextConversationId(conversationIdList);
            conversationRef.current = {title: t('新会话'), messages: newHistory, id: nextId};
            setConversationIdList([...conversationIdList, conversationRef.current.id]);
        } else {
            conversationRef.current.messages = newHistory;
        }
        setConversation(conversationRef.current).then();
        setRequesting(true);

        if (config.workingMode === 'cloud') {
            _askChatAI(newHistory, conversationRef.current);
        } else {
            askOpenAI(newHistory, conversationRef.current).then();
        }
    }

    function switchCurrentIndexToMd(event, i) {
        event.preventDefault();
        if (showMDIndexSet.has(i)) {
            setShowMDIndexSet(new Set([...showMDIndexSet].filter((item) => item !== i)));
        } else {
            setShowMDIndexSet(new Set([...showMDIndexSet, i]));
        }
    }

    async function handleSendConversationToEmail(event) {
        event.preventDefault();
        if (requesting) {
            return;
        }
        const currentConversation = await getConversionById(getId);
        if (currentConversation.messages.length === 0) {
            return;
        }
        const confirm = window.confirm(t('当前会话历史的纯文本内容会通过落格智聊服务器发送到您的邮箱，是否确认发送？')
        );
        if (!confirm) {
            return;
        }
        setRequesting(true);
        sendConversationToEmail(authUser, currentConversation.messages, currentConversation.title).then(() => {
            setEmailChecked(true);
            setRequesting(false);
        });
    }

    function handleEditLastMessage(event) {
        event.preventDefault();
        if (requesting) {
            return;
        }
        if (conversationRef.current.messages.length === 0) {
            return;
        }
        let lastMessage = conversationRef.current.messages.pop();
        if (lastMessage.sender !== 'Human') {
            lastMessage = conversationRef.current.messages.pop();
        }
        if (lastMessage.sender !== 'Human') {
            return;
        }
        const newHistory = [...conversationRef.current.messages];
        setChatHistory(newHistory);
        chatInputRef.current.innerText = lastMessage.content;
        setSelectedImages(lastMessage.images.map(img => ({id: Math.random(), src: img, isRemoving: false})))
        setConversation(conversationRef.current);
        chatInputRef.current.focus();
    }

    function handleReAnswerLastMessage(event) {
        event.preventDefault();
        if (requesting) {
            return;
        }
        const conversationStore = conversationRef.current;
        if (conversationStore.messages.length === 0) {
            return;
        }
        const removed = conversationStore.messages.pop();
        const lastMessage = conversationStore.messages[conversationStore.messages.length - 1];
        if (lastMessage.sender !== 'Human') {
            conversationStore.messages.push(removed);
            return;
        }
        const newHistory = [...conversationStore.messages];
        setChatHistory(newHistory);
        setConversation(conversationStore);
        setRequesting(true);
        if (config.workingMode === 'cloud') {
            _askChatAI(newHistory, conversationStore);
        } else {
            askOpenAI(newHistory, conversationStore);
        }
    }

    function handleStopGeneration(event) {
        event.preventDefault();
        if (requesting) {
            streamControllerRef.current.abort();
            setRequesting(false);
        }
    }

    function handleContinueAnswering(event) {
        const newItem = getChatItem('Human', 'continue');
        newItem.continued = true;
        const newHistory = [...chatHistory, newItem];
        setRequesting(true);
        if (config.workingMode === 'cloud') {
            _askChatAI(newHistory, conversationRef.current);
        } else {
            askOpenAI(newHistory, conversationRef.current);
        }
    }

    function compressImage(base64String, quality = 0.7, maxSizeMB = 1) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;

                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                let compressedBase64 = canvas.toDataURL('image/jpeg', quality);

                // Check if the size is still too large
                while (getBase64Size(compressedBase64) > maxSizeMB * 1024 * 1024 && quality > 0.1) {
                    quality -= 0.1;
                    compressedBase64 = canvas.toDataURL('image/jpeg', quality);
                }

                resolve(compressedBase64);
            };
            img.onerror = function () {
                reject(new Error('Failed to load image'));
            };
            img.src = base64String;
        });
    }

    // a function to convert heic to jpg if necessary
    function convertHeicToJpg(base64String) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                const dataUrl = canvas.toDataURL('image/jpeg');
                resolve(dataUrl);
            };
            img.onerror = function () {
                reject(new Error('Failed to load image'));
            };
            img.src = base64String;
        });
    }

    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        // generate a random id for each image
        files.forEach(async file => {
            const reader = new FileReader();
            let f = file
            if (await isHeic(file)) {
                f = await heicTo({
                    blob: file,
                    type: "image/jpeg",
                    quality: 1
                })
            }

            reader.onloadend = async () => {
                let fileBase64 = reader.result;
                if (file.type === 'image/heic') {
                    fileBase64 = await convertHeicToJpg(fileBase64);
                }
                let overSize = getBase64Size(fileBase64) > 3.5 * 1024 * 1024
                // check image size over 5mb
                if (overSize) {
                    await smalltalk
                        .alert(file.name, t('图片大小不能超过3.5MB，压缩吗？'), {
                            buttons: {
                                ok: t('压缩'),
                                cancel: t('取消'),
                            }
                        })
                        .then(async () => {
                            fileBase64 = await compressImage(fileBase64);
                            overSize = false;
                            console.log('compressed!')
                        })
                        .catch(() => {
                            // user canceled
                            console.log('User canceled image upload');
                        });
                }
                if (!overSize) {
                    setSelectedImages(prevImages => [
                        ...prevImages,
                        {id: Math.random(), src: fileBase64, isRemoving: false}
                    ]);
                }
            };
            reader.readAsDataURL(f);
        });
    };

    const handleRemoveImage = (id) => {
        setSelectedImages(prevImages =>
            prevImages.map(img =>
                img.id === id ? {...img, isRemoving: true} : img
            )
        );

        setTimeout(() => {
            setSelectedImages(prevImages => prevImages.filter(img => img.id !== id));
        }, 300); // 与CSS transition duration相匹配
    };

    function handleOverrideModel(selected) {

        let currentId = getId;
        if (conversationIdList.length === 0 || currentId === -1) {
            const nextId = nextConversationId(conversationIdList);
            conversationRef.current = {title: t('新会话'), messages: [], id: nextId};
            currentId = nextId;
            setConversationIdList([...conversationIdList, conversationRef.current.id]);
        }
        const currentModel = conversationRef.current.model || 'default';
        if (currentModel === selected) {
            return;
        }
        if (selected === 'default') {
            delete conversationRef.current.model;
        } else {
            conversationRef.current.model = selected;
        }
        setSelectedModel(selected);
        setConversation(conversationRef.current);
        setTimeout(() => {
            if (currentId !== getId) {
                setId(currentId);
            }
            setFlip(!flip);
        }, 200);
    }

    function handleSetImageDetail(selected) {

        let currentId = getId;
        if (conversationIdList.length === 0 || currentId === -1) {
            const nextId = nextConversationId(conversationIdList);
            conversationRef.current = {title: t('新会话'), messages: [], id: nextId};
            currentId = nextId;
        }
        conversationRef.current.imageDetail = selected;
        setImageDetail(selected);
        setConversation(conversationRef.current);
        setTimeout(() => {
            setId(currentId);
        }, 200);
    }

    function handleSetReasoningEffort(selected) {
        let currentId = getId;
        if (conversationIdList.length === 0 || currentId === -1) {
            const nextId = nextConversationId(conversationIdList);
            conversationRef.current = {title: t('新会话'), messages: [], id: nextId};
            currentId = nextId;
        }
        conversationRef.current.reasoningEffort = selected;
        setModelReasoningEffort(selected);
        setConversation(conversationRef.current);
        setTimeout(() => {
            setId(currentId);
        }, 200);
    }

    const genReasoningEffort = () => {
        if (selectedModel === 'claude-3.7-sonnet') {
            return ['none','low', 'medium', 'high']
        } else {
            return ['low', 'medium', 'high']
        }
    }

    const BGNotice = () => {
        const Block = (prop) => (
            <div
                className={`dark:bg-gray-800 bg-gray-200 px-3 py-2 text-sm rounded-lg dark:text-gray-400 text-gray-500 ${isMobileVersion() ? 'm-1' : 'm-3'}  w-full text-center`}>
                {prop.children}
            </div>
        );
        return (
            <div
                className={`h-1/2 grow w-full max-w-4xl ${isMobileVersion() ? 'px-5' : 'px-32'} dark:text-gray-500 text-gray-400 flex flex-col items-center overflow-x-clip overflow-y-scroll no-scrollbar`}>
                <span className={`text-2xl  ${isMobileVersion() ? 'mt-10' : 'my-10'} font-bold`}>{t('落格智聊')}</span>
                <div className={`flex ${isMobileVersion() ? 'gap-2' : 'gap-10'} `}>
                    <div className={'flex flex-col items-center max-w-sm flex-grow-0'}>
                        <span className={`text-lg font-bold my-2`}>
                            {t('功能')}
                        </span>
                        <Block>
                            {t('回答用户问题，自动谷歌搜索')}
                        </Block>
                        <Block>
                            {t('根据用户要求生成文本，如文章、代码、诗歌等')}
                        </Block>
                        <Block>
                            {t('访问网页，根据用户提供的网页链接内容生成文本或回答问题')}
                        </Block>
                    </div>

                    <div className={'flex flex-col items-center  max-w-sm flex-grow-0'}>
                        <span className={`text-lg font-bold my-2`}>
                            {t('限制')}
                        </span>
                        <Block>
                            {t('回答或生成的内容可能不准确，请小心查证')}
                        </Block>
                        <Block>
                            {t('单个会话历史无限长度，但模型处理的文本长度有限，超过长度会被截断')}
                        </Block>
                        <Block>
                            <p>{t('GPT3.5 模型知识截至 2021 年 9 月')}</p>
                            <p>{t('GPT4 和 Gemini 模型知识截至 2023 年 4 月')}</p>
                            <p>{t('Claude3 模型知识截至 2023 年 8 月')}</p>
                        </Block>
                    </div>

                    <div className={'flex flex-col items-center max-w-sm flex-grow-0'}>
                        <span className={'text-lg font-bold my-2'}>
                            {t('提示')}
                        </span>
                        <Block>
                            {t('请勿使用落格智聊进行违法违规活动')}
                        </Block>
                        <Block>
                            {t('点击右上角菜单按钮可打开菜单栏')}
                        </Block>
                        <Block>
                            {t('落格智聊支持 API 调用, API 支持 chat, embedding, whisper, tts 以及 stt，详情见菜单栏说明')}
                        </Block>
                        <Block>
                            {t('充值入口在菜单栏底部')}
                        </Block>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <main className="w-full flex flex-col grow overflow-clip items-center">
            {chatHistory.length === 0 && <BGNotice/>}
            <SimpleBar forceVisible="y" autoHide={true}
                       className={`chatFrame h-1/2 grow mb-2 max-w-4xl w-full px-5 z-0 ${chatHistory.length === 0 ? 'hidden' : ''}`}>
                <ul className={'chatHistory w-full' + isMobileVersion() && isClient() ? ' pt-14 ' : ''}>
                    {chatHistory.map((msg, index) => {
                        return <ChatItem msg={msg} index={index} key={index} showMDIndexSet={showMDIndexSet}
                                         showButtonIndexMobile={showButtonIndexMobile}
                                         setShowButtonIndexMobile={setShowButtonIndexMobile}
                                         mdRender={mdRender} copyElementToClipboard={copyElementToClipboard}
                                         switchCurrentIndexToMd={switchCurrentIndexToMd} chatHistory={chatHistory}
                                         handleReAnswerLastMessage={handleReAnswerLastMessage}
                                         handleEditLastMessage={handleEditLastMessage}
                                         config={config}/>;
                    })}
                    {(streamCache.current !== '' || reasoningCache.current !== '') && <li
                        className={`chat ${isMobileVersion() ? 'p-2' : 'px-5 py-2'} bg-gray-300 dark:bg-slate-700 dark:text-white rounded`}
                        key={chatHistory.length}>
                        {streamCache.current === '[--Loading--]' && reasoningCache.current === '' ?
                            <div className={'flex items-center'}>
                                <p className={`${isMobileVersion() ? 'pt-2 pb-2' : 'pt-4 pb-4'}`}>
                                    {'💡'}
                                </p>
                                <Grid
                                    height="15"
                                    width="15"
                                    color="#3B82F6"
                                    ariaLabel="grid-loading"
                                    radius="10.5"
                                    wrapperStyle={{}}
                                    wrapperClass={`pl-2  ${isMobileVersion() ? 'pt-2 pb-2 ml-2' : 'pt-4 pb-4 ml-4'}`}
                                />
                            </div>
                            :
                            <div className={'flex'}>
                                <p className={`${isMobileVersion() ? 'pt-2' : 'pt-4'}`}>
                                    {streamCache.current !== '' ? '💡' : '💭'}
                                </p>
                                {streamCache.current !== '' ?
                                    <div className={`${isMobileVersion() ? 'p-2' : 'p-4'} ml-2 dark:text-white`}>
                                        {parseHtml(mdRender.current.render(streamContent + '█'))}
                                    </div>
                                    :
                                    <div className={`${isMobileVersion() ? 'p-2' : 'p-4'} ml-2 text-gray-800 dark:text-gray-300 text-xs`}>
                                        {parseHtml(mdRender.current.render(reasoningContent + '...'))}
                                    </div>
                                }
                            </div>
                        }
                    </li>}
                    {(streamCache.current !== '' || streamCache.current === '[--Loading--]') &&
                        <li className={'dark:text-white flex justify-center'}>
                            <button
                                className={'flex items-center gap-2 bg-blue-500 md:hover:bg-blue-700 dark:bg-slate-700 md:dark:hover:bg-slate-600 text-white font-bold py-2 px-4 rounded h-10 disabled:opacity-50 disabled:hover:bg-blue-500 text-sm'}
                                onClick={handleStopGeneration}>
                                <StopCircleIcon className="w-5 h-5"/>
                                {t('停止回答')}
                            </button>
                        </li>
                    }
                    {streamCache.current === '' && chatHistory.length > 0 && chatHistory[chatHistory.length - 1].sender === 'AI' &&
                        <li className={'dark:text-white flex justify-center gap-2 pt-3'}>
                            <button
                                className={'flex items-center gap-2 bg-blue-500 md:hover:bg-blue-700 dark:bg-slate-700 md:dark:hover:bg-slate-600 text-white font-bold py-2 px-4 rounded h-10 disabled:opacity-50 disabled:hover:bg-blue-500 text-sm'}
                                onClick={handleReAnswerLastMessage}>
                                <ArrowPathIcon className="w-4 h-4"/>
                                {t('重新回答')}
                            </button>
                            {canContinue &&
                                <button
                                    className={'flex items-center gap-2 bg-blue-500 md:hover:bg-blue-700 dark:bg-slate-700 md:dark:hover:bg-slate-600 text-white font-bold py-2 px-4 rounded h-10 disabled:opacity-50 disabled:hover:bg-blue-500 text-sm'}
                                    onClick={handleContinueAnswering}>
                                    <ArrowsUpDownIcon className="w-4 h-4"/>
                                    {t('继续回答')}
                                </button>
                            }
                        </li>
                    }

                    <li aria-hidden={true} ref={(el) => {
                        scrollBottomDivRef.current = el;
                    }} className={'mt-10'}></li>
                </ul>
            </SimpleBar>
            <div className={`typingArea ${isMobileVersion() ? 'mb-2' : 'mb-10'} px-5 max-w-4xl w-full`}>
                {isMobileVersion() && <MobileToolbar/>}
                <div className="flex flex-col w-full">
                    {selectedImages.length > 0 && (
                        <div className="mb-2 flex flex-wrap gap-2">
                            {selectedImages.map((image) => (
                                <div
                                    key={image.id}
                                    className={`relative group transition-all duration-300 ease-in-out ${
                                        image.isRemoving ? 'w-0 m-0 opacity-0' : 'w-20 h-20'
                                    }`}
                                >
                                    <img
                                        src={image.src}
                                        alt={`Selected ${image.id}`}
                                        onClick={() => setSelectedImageChatArea(image.src)}
                                        className="h-full w-full object-cover rounded transition-transform duration-300 ease-in-out transform group-hover:scale-105"
                                    />
                                    <button
                                        onClick={() => handleRemoveImage(image.id)}
                                        className="absolute top-1 right-1 bg-gray-800 bg-opacity-50 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out hover:bg-opacity-75"
                                        aria-label="Remove image"
                                    >
                                        <XMarkIcon className="h-4 w-4"/>
                                    </button>
                                </div>
                            ))}
                            {selectedImageChatArea && (
                                <ImageModal
                                    src={selectedImageChatArea}
                                    alt="Full size image"
                                    onClose={() => setSelectedImageChatArea(null)}
                                />
                            )}
                        </div>
                    )}
                    <div className="flex flex-row items-end" role={'form'}>
                        <div
                            contentEditable={chatInputEditable ? `${isFirefox ? 'true' : 'plaintext-only'}` : 'false'}
                            role="textbox"
                            aria-label="消息输入框"
                            ref={chatInputRef}
                            onKeyDown={handleChatInputKeyDown}
                            onInput={handleChatInput}
                            className={`w-full max-h-80 min-h-10 overflow-y-scroll hide-scrollbar border border-gray-300 dark:border-gray-600 dark:text-white rounded px-2 py-2 flex flex-col ${!chatInputEditable && 'bg-gray-100 dark:bg-gray-800'}`}
                        ></div>
                        <div className="ml-2 flex">
                            <input
                                type="file"
                                id="imageInput"
                                accept="image/*"
                                multiple
                                className="hidden"
                                onChange={handleImageUpload}
                            />
                            {modelSupportImages && <button
                                aria-label="上传图片"
                                className="mr-2 bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 font-bold py-2 px-4 rounded h-10"
                                onClick={() => document.getElementById('imageInput').click()}
                            >
                                <PhotoIcon className="w-5 h-5"/>
                            </button>}
                            <button
                                aria-label="发送"
                                className="transition-colors duration-200 ease-in-out bg-blue-500 md:hover:bg-blue-700 dark:bg-slate-700 md:dark:hover:bg-slate-600 text-white font-bold py-2 px-4 rounded h-10 disabled:opacity-50 disabled:hover:bg-blue-500"
                                disabled={(!config.token && !authUser) || requesting}
                                onClick={handleChatSubmit}
                            >
                                {requesting ? (
                                    <Grid
                                        height="20"
                                        width="20"
                                        color="#fff"
                                        ariaLabel="grid-loading"
                                        radius="10.5"
                                        wrapperStyle={{}}
                                        wrapperClass="justify-center"
                                    />
                                ) : (
                                    <PaperAirplaneIcon className="w-5 h-5"/>
                                )}
                            </button>
                        </div>
                    </div>
                </div>

                <div className={'flex grow justify-between text-xs mt-3  dark:text-gray-300 items-center'}>

                    <div>
                        {config.workingMode === 'local' ?
                            // if it's local mode, we show a warning instead of email button
                            <button
                                className={`flex gap-2 bg-red-500 dark:bg-red-700 px-2 py-1 rounded disabled:opacity-50 text-white`}
                                disabled={true}>
                                {t('用户自备 OpenAI Token')}
                            </button>
                            :
                            <button
                                className={`flex gap-2 bg-blue-500 dark:bg-slate-700 px-2 py-1 rounded disabled:opacity-50 text-white`}
                                disabled={requesting || chatHistory.length === 0 || emailChecked}
                                onClick={handleSendConversationToEmail}>
                                {t('发送会话历史到邮箱')}
                                {emailChecked &&
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor"
                                         className="w-4 h-4 text-green-300">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"/>
                                    </svg>
                                }
                            </button>
                        }
                    </div>
                    <div className={'grow'}>
                        <InlineDropdown
                            disabled={conversationIdList.length === 0}
                            prompt={t('模型: ')}
                            selected={selectedModel}
                            setSelected={handleOverrideModel}
                            options={Object.keys(chatAIModels).concat(['default'])}/>
                    </div>
                    {modelSupportReasoning && <div className={'grow text-right'}>
                        <InlineDropdown
                            prompt={t('思考力度: ')}
                            selected={modelReasoningEffort}
                            setSelected={handleSetReasoningEffort}
                            options={genReasoningEffort()}/>
                    </div>}
                    {modelSupportImages && needDetailFor4o && <div className={'grow text-right'}>
                        <InlineDropdown
                            prompt={t('图像细节: ')}
                            selected={imageDetail}
                            setSelected={handleSetImageDetail}
                            options={['low', 'high']}/>
                    </div>}
                    <div className={'flex gap-2 underline ml-2'}>
                        <a className=" py-2 rounded"
                           role={'button'}
                           href={'https://t.me/joinchat/DGZalT6pMAHNNg8JyfPLXw'} target="_blank" rel="noreferrer">
                            {t('Telegram 加群')}
                        </a>
                        <a className="  py-2 rounded"
                           role={'button'}
                           href={'https://im.logcg.com/archives/2399'} target="_blank" rel="noreferrer">
                            {t('常见问题解答')}
                        </a>
                    </div>

                </div>
            </div>
        </main>
    );
}
