import {isDesktopVersion, isMobileVersion, copySvg, parseHtml} from '../Utils';
import {ArrowPathIcon, PencilSquareIcon, GlobeAltIcon, ChatBubbleOvalLeftEllipsisIcon} from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import ImageModal from "./ImageModal";
import {useTranslation} from 'react-i18next';
import smalltalk from 'smalltalk';


/**
 *
 * @param msg
 * @param index
 * @param showMDIndexSet
 * @param showButtonIndexMobile
 * @param setShowButtonIndexMobile
 * @param mdRender
 * @param copyElementToClipboard
 * @param switchCurrentIndexToMd
 * @param addSVGIcon
 * @param chatHistory
 * @param handleReAnswerLastMessage
 * @param handleEditLastMessage
 * @param config
 * @return {JSX.Element}
 * @constructor
 */
function ChatItem({
                      msg,
                      index,
                      showMDIndexSet,
                      showButtonIndexMobile,
                      setShowButtonIndexMobile,
                      mdRender,
                      copyElementToClipboard,
                      switchCurrentIndexToMd,
                      chatHistory,
                      handleReAnswerLastMessage,
                      handleEditLastMessage,
                      config
                  }) {
    const {t} = useTranslation();
    const [selectedImage, setSelectedImage] = useState(null);

    const renderImages = (images) => {
        if (!images || images.length === 0) return null;

        return (
            <div className={`flex flex-wrap gap-2 ml-2 ${isMobileVersion() ? 'p-2' : 'p-4'}`}>
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Uploaded ${index + 1}`}
                        className="max-w-xs max-h-24 object-cover rounded"
                        onClick={() => setSelectedImage(image)}
                    />
                ))}
            </div>
        );
    };

    const ToolBar = (prop) => {
        const className = prop.className;
        return <div
            className={`${className} absolute bg-gray-200 dark:bg-slate-600 rounded p-0 ${isDesktopVersion() && 'group-hover:block'} dark:text-white ${(showMDIndexSet.has(index) && isDesktopVersion()) || showButtonIndexMobile === index ? '' : 'hidden'}`}>
            <button onClick={(event) => switchCurrentIndexToMd(event, index)}
                    title={t('切换纯文本')}
                    aria-label={'切换纯文本'}
                    className={'block hover:bg-gray-400 py-1 px-2 mx-auto dark:hover:bg-gray-800'}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                     className="bi bi-markdown w-5 h-5" viewBox="0 0 16 16">
                    <path
                        d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"/>
                    <path fillRule="evenodd"
                          d="M9.146 8.146a.5.5 0 0 1 .708 0L11.5 9.793l1.646-1.647a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 0-.708z"/>
                    <path fillRule="evenodd" d="M11.5 5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 1 .5-.5z"/>
                    <path
                        d="M3.56 11V7.01h.056l1.428 3.239h.774l1.42-3.24h.056V11h1.073V5.001h-1.2l-1.71 3.894h-.039l-1.71-3.894H2.5V11h1.06z"/>
                </svg>
            </button>

            <button onClick={(event) => copyElementToClipboard(event, `msg-${index}`)}
                    title={t('复制内容')}
                    aria-label={'复制内容'}
                    className={'block mt-1 hover:bg-gray-400 py-1 px-2 mx-auto dark:hover:bg-gray-800'}>
                {copySvg}
            </button>

            {msg.sender === 'AI' && index === chatHistory.length - 1 &&
                <button onClick={handleReAnswerLastMessage}
                        title={t('重新回答')}
                        aria-label={'重新回答'}
                        className={'block mt-1 hover:bg-gray-400 py-1 px-2 mx-auto dark:hover:bg-gray-800'}>
                    <ArrowPathIcon className="w-5 h-5"/>
                </button>
            }
            {msg.sender === 'Human' && (index === chatHistory.length - 2 || index === chatHistory.length - 1) &&
                <button onClick={handleEditLastMessage}
                        title={t('编辑')}
                        aria-label={'编辑'}
                        className={'block mt-1 hover:bg-gray-400 py-1 px-2 mx-auto dark:hover:bg-gray-800'}>
                    <PencilSquareIcon className="w-5 h-5"/>
                </button>
            }
        </div>;
    };

    return (
        <>
            <li className={`chat ${isMobileVersion() ? 'p-2' : 'px-5 py-2'} relevant group rounded` +
                (msg.sender === 'AI' ? ' bg-gray-200 dark:bg-slate-700' : '') +
                (msg.sender === 'SYSTEM' ? ' text-center text-xs dark:text-gray-200 text-gray-800 bg-red-300 dark:bg-red-900' : '')}
                tabIndex={0}
                aria-label={`${msg.sender}: ${msg.content}`}
                onClick={(event) => {
                    if (isDesktopVersion()) return;
                    event.preventDefault();
                    if (showButtonIndexMobile === index) {
                        setShowButtonIndexMobile(-1);
                    } else {
                        setShowButtonIndexMobile(index);
                    }
                }}
            >
                <div
                    className={'chatText flex'}>
                    <p className={`${isMobileVersion() ? 'pt-2' : 'pt-4'}`}>
                        {msg.sender === 'AI' && '💡'}
                        {msg.sender === 'Human' && '👤'}
                        {msg.sender === 'SYSTEM' && '⚠️'}
                    </p>
                    <ToolBar className={'left-7 mt-12'}/>
                    <div>
                        {renderImages(msg.images)}
                        {showMDIndexSet.has(index) ?
                            <pre id={`msg-${index}`}
                                 className={`${isMobileVersion() ? 'p-2' : 'p-4'} ml-2 dark:text-white whitespace-pre-wrap overflow-x-scroll`}>
                                            {msg.content}
                                        </pre>
                            :
                            <div id={`msg-${index}`}
                                 className={`${isMobileVersion() ? 'p-2' : 'p-4'} ml-2 dark:text-white break-words overflow-x-scroll hide-scrollbar`}
                            >
                                {parseHtml(mdRender.current.render(msg.content))}
                            </div>
                        }
                    </div>


                </div>

                {msg.sender === 'AI' && msg.mode === 'local' && config.showUsage &&
                    <div
                        className={'chatCost text-xs text-gray-500 dark:text-gray-300 text-right mt-4'}>{t('开销')}:
                        ${parseFloat(msg.cost.toFixed(6))} {t('美元')}</div>}
                {msg.sender === 'AI' && msg.mode === 'cloud' &&
                    <div className={'flex justify-between items-center pl-5'}>
                        <div className={'flex text-xs ml-5 gap-3 '}>
                            {msg.fact &&
                                <button title={t('该会话包含谷歌搜索结果。')}
                                        className={'border rounded px-2 py-1 dark:border-gray-500 dark:text-gray-50 border-white text-gray-600'}
                                        onClick={() => {
                                            smalltalk.alert(t('该会话包含谷歌搜索结果。'), msg.fact.replace(/^\[.+]/, ''), {
                                                buttons: {
                                                    ok: t('好')
                                                }
                                            }).catch(() => {

                                            });
                                        }}>G</button>
                            }
                            {msg.webContents && msg.webContents.length > 0 &&
                                <button title={t('该会话包含网页链接内容。')}
                                        className={'border rounded px-2 py-1 dark:border-gray-500 dark:text-gray-50 border-white text-gray-600'}
                                        onClick={() => {
                                            const webContents = msg.webContents.map((content) => {
                                                return content.replace(/^\[.+]/, '');
                                            }).join('\n');
                                            smalltalk.alert(t('该会话包含网页链接内容。'), webContents, {
                                                buttons: {
                                                    ok: t('好')
                                                }
                                            }).catch(() => {

                                            });
                                        }}><GlobeAltIcon className={'w-4 h-4'}/></button>
                            }
                            {msg.reasoningContent && msg.reasoningContent.length > 0 &&
                                <button title={t('该会话包含思考内容。')}
                                        className={'border rounded px-2 py-1 dark:border-gray-500 dark:text-gray-50 border-white text-gray-600'}
                                        onClick={() => {
                                            smalltalk.alert(t('该会话包含思考内容。'), msg.reasoningContent, {
                                                buttons: {
                                                    ok: t('好')
                                                }
                                            }).catch(() => {

                                            });
                                        }}><ChatBubbleOvalLeftEllipsisIcon className={'w-4 h-4'}/></button>
                            }
                        </div>
                        {config.showUsage &&
                            <div
                                className={'chatCost text-xs text-gray-500 dark:text-gray-300 mt-4'}>{t('开销')}: {parseFloat(msg.cost.toFixed(6))} {t('点数')}
                            </div>
                        }
                    </div>
                }
            </li>
            {selectedImage && (
                <ImageModal
                    src={selectedImage}
                    alt="Full size image"
                    onClose={() => setSelectedImage(null)}
                />
            )}
        </>

    );
}

export default React.memo(ChatItem, (prevProps, nextProps) => {
    const selectedOrWasSelected = (prevProps.showButtonIndexMobile === prevProps.index || nextProps.showButtonIndexMobile === nextProps.index) && isMobileVersion();
    const imagesChanged = !prevProps.msg.images?.every((img, i) => img === nextProps.msg.images?.[i]) || prevProps.msg.images?.length !== nextProps.msg.images?.length;
    return prevProps.msg === nextProps.msg && !selectedOrWasSelected && prevProps.showMDIndexSet === nextProps.showMDIndexSet && !imagesChanged;
});
